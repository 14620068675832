import React from "react";
import Dropdown from "../../multi-level-dropdown";

import { jsonMenu } from "../../data/TopMenuData";

import configData from "../../config.json";

import TokenStorage from "../../api/TokenStorage";
const tokenStorageService = TokenStorage.getService();

export default function MenuBar(props) {

  const onClick = (item) => {
    if(props.menuCallback){
      props.menuCallback({
          pathname: item.route,
          state: { ticker: item.param, name: item.name, shortName: item.shortName, screen: props.screen },
        })
    }
};

const onClickHeader = (item) => {
  if(props.menuCallback && item.route){
    props.menuCallback({
        pathname: item.route,
        state: { ticker: item.param, name: item.name, shortName: item.shortName, screen: props.screen },
      })
    return 'ACTION'
  }
};

const generateMenuItem = (menuItem, topLabel) => {
    if (
      ( typeof menuItem.tenant === "undefined" ||
        (menuItem.tenant != null && menuItem.tenant === configData.TENANT)) &&
      (typeof menuItem.role === "undefined" ||
        (menuItem.role != null && roles.includes(menuItem.role)))
    ) {
      return (
        <Dropdown.Item onClick={() => onClick(menuItem)} key={topLabel+menuItem.label}>
          {menuItem.label}
        </Dropdown.Item>
      );
    } else return null;
  };

  const generateOneMenu = (item, roles) => {
    if (
      (typeof item.tenant === "undefined" ||
        (item.tenant != null && item.tenant === configData.TENANT)) &&
      (typeof item.role === "undefined" ||
        (item.role != null && roles.includes(item.role)))
    ) {
      return (
        <Dropdown position={"right"} title={item.title} key={item.title} onClick={() => onClickHeader(item)} >
          {item.menuItems.map((menuItem) => generateMenuItem(menuItem, item.title))}
        </Dropdown>
      );
    } else {
      return null;
    }
  };

   
  const createMenus = (menus, roles) => {
    return menus.map((oneMenu) => {
      if (
        (typeof oneMenu.tenant === "undefined" ||
          (oneMenu.tenant != null && oneMenu.tenant === configData.TENANT)) &&
        (typeof oneMenu.role === "undefined" ||
          (oneMenu.role != null && (roles && roles.includes(oneMenu.role))))
      ) {
        return generateOneMenu(oneMenu, roles)
        }
        else
          return null
      }
    );
  };

  let roles = tokenStorageService.getRoles();

  return <div>{createMenus(jsonMenu, roles)}</div>;
}
