import React, { useEffect, useState } from "react";

import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { format, subDays } from 'date-fns'

import "./dateselector.css";

export default function DateSelector({callback}) {

  const [dateRange, setDateRange] = useState([subDays(new Date(), 14), new Date()]);

  useEffect(() => {
    let s = format(dateRange[0], 'yyyy-MM-dd');
    let e = format(dateRange[1], 'yyyy-MM-dd');
    callback([s, e]);
  }, [dateRange]);

  //handle null case (clear button)
  const handleDateChange = (date) => {
    if(date) {
      setDateRange(date);
    } else {
      setDateRange([new Date('2022-05-01'), new Date()]);
    }
  }
  
  return (
    <DateRangePicker onChange={handleDateChange} value={dateRange} />
  );
}