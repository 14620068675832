import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

import "../../styles.css";

export default function TnC({showTandC, handleCloseTandC}) {

  return (
    <Dialog
    onClose={handleCloseTandC}
    aria-labelledby="customized-dialog-title"
    open={showTandC}
  >
    <DialogTitle id="customized-dialog-title" onClose={handleCloseTandC}>
      Asperios B2B Beta Testing Agreement Terms & Conditions
    </DialogTitle>
    <DialogContent dividers>
      <Typography gutterBottom>
      <b>1. Scope of Agreement</b> Beta Customer is being granted rights under this Agreement for the purpose of testing and providing input and other Feedback to Semantic Brain Corp regarding one or more of Semantic Brain Corp’s proprietary, non-commercially available hosted software applications, application platform interfaces, services, features and/or functionalities in connection with the Existing Account as more fully described in the Beta Testing Order Form (the “Beta Product(s)”). This Agreement covers all Beta Products made available to Beta Customer by Semantic Brain Corp, including, without limitation, any Beta Products specifically identified by Semantic Brain Corp as being in “Beta” or any similar stage of development. Semantic Brain Corp retains sole and absolute discretion as to what, if any, Beta Products will be made available to Beta Customer during the Term. While Beta Customer is not required to utilize or enable any Beta Products, if Beta Customer elects to do so, then Beta Customer’s use of and access to any such Beta Products shall be subject to the terms of this Agreement.
      </Typography>
      <Typography gutterBottom>
      <b>2. Payment and Fees</b> Semantic Brain Corp reserves the right to charge fees for use and access to the Beta Products or may provide the Beta Products for no charge. Semantic Brain Corp reserves the right to start charging or revise fee amounts at any time, at its sole discretion, under this Agreement or after the Beta Products are made widely available and incorporated into a Semantic Brain Corp service.
      </Typography>
      <Typography gutterBottom>
      <b>3. Limited Use Rights</b> For the term of the Agreement, Semantic Brain Corp grants to Beta Customer a limited, non-transferable, non-exclusive, revocable right to access and use the Beta Product(s) in connection with an Existing Account to test its functionality and provide Feedback to Semantic Brain Corp. Semantic Brain Corp hosts and retains physical control over the Beta Product(s) and only makes the Beta Product(s) available for access and use by Beta Customer over the Internet through a Web-browser or mobile device. Nothing in this Agreement obligates Semantic Brain Corp to deliver or make available any copies of computer programs or code from the Beta Product(s) to Customer, whether in object code or source code form. Semantic Brain Corp reserves the right, in its sole discretion, to revoke access and use of the Beta Products at any time. Further, Semantic Brain Corp reserves the right to enter (“assume into”) the Existing Account as needed to (i) monitor or measure use of the Beta Product(s); (ii) validate certain features or functionality of the Beta Product(s); and (iii) to provide services or support necessary to maintain the Beta Product(s).
      </Typography>
      <Typography gutterBottom>
      <b>4. Use Restrictions</b> Customer may not rent, lease, distribute, or resell the Beta Product(s), or use the Beta Product(s) as the basis for developing a competitive solution (or contract with a third party to do so), or remove or alter any of the logos, trademark, patent or copyright notices, confidentiality or proprietary legends or other notices or markings that are on or in the Beta Product(s).
      </Typography>
      <Typography gutterBottom>
      <b>5. Feedback</b> Upon reasonable request by Semantic Brain Corp, Beta Customer agrees to provide suggestions, enhancement requests, and recommendations (individually and collectively, “Feedback”) regarding the Beta Product(s). Feedback shall include informing Semantic Brain Corp about the performance, ease of use, features that may be missing, and any bugs encountered during the use of the Beta Product(s). Semantic Brain Corp may contact Beta Customer and Beta Customer agrees to make available a reasonable amount of time to discuss the Beta Product(s) with Semantic Brain Corp if so requested. Semantic Brain Corp may without restriction or fee use, modify and incorporate this Feedback into the Beta Product(s) and other Semantic Brain Corp products and/or services without any restriction and without any payment.
      </Typography>
      <Typography gutterBottom>
      <b>6. Intellectual Property</b> The parties acknowledge that this Agreement does not transfer any right, title or interest in any intellectual property right to the other. Semantic Brain Corp maintains all rights, title and interest in and to all its patents, inventions, copyrights, trademarks, domain names, trade secrets, know-how and any other intellectual property and/or proprietary rights (collectively, “Intellectual Property Rights”). The limited rights granted to Customer to access and use the Beta Product(s) under this Agreement do not convey any additional rights in the Beta Product(s), or in or to any Intellectual Property Rights associated therewith. Subject only to the limited rights to access and use the Beta Product(s) as expressly provided herein, all rights, title and interest in and to the Beta Product(s) and all hardware, software and other components of or used to provide the Beta Product(s), including all related Intellectual Property Rights, will remain with and belong exclusively to Semantic Brain Corp.
      </Typography>
      <Typography gutterBottom>
      <b>7. Term of Agreement and Renewal</b> This Agreement shall commence upon the Beta Testing Start Date and shall expire on the Beta Testing End Date, each as set forth in the Beta Testing Order Form (the “Initial Term”). Upon expiration, this Agreement shall automatically renew for subsequent one (1) month periods until terminated by either party (each a “Renewal Term” and, collectively with the Initial Term, the “Term”).
      </Typography>
      <Typography gutterBottom>
      <b>8. Termination and Expiration</b> Either party may terminate this Agreement at any time, for any or no reason, and at either party’s convenience by providing written notice to the other. Upon termination of this Agreement, Customer will notify its Users that their access to the Beta Product(s) has terminated, and Semantic Brain Corp may withhold, remove or discard any content, data, or other information that Customer’s Users post or upload into Semantic Brain Corp’s system while using the Beta Product(s). Following termination or expiration of this Agreement, Semantic Brain Corp is not obligated to store, maintain or provide a copy of any content, data or other information that Customer or its Users made available or provided when using the Beta Product(s). If not earlier terminated, Customer’s obligations under this Agreement shall terminate upon termination of this Agreement; provided that the foregoing shall not limit Semantic Brain Corp’s rights pursuant to Section 5 as related to any Feedback provided before or after such termination. Sections 2, 5, 6, 11, and 13 to 17, and all obligations thereunder, shall survive any termination of this Agreement.
      </Typography>
      <Typography gutterBottom>
      <b>9. Users</b> Customer will not, and will not permit its Users to, share Beta Product access rights with any other individual. Customer will ensure that its Users comply with these Terms and Conditions. In the event a User violates the Terms and Conditions, Semantic Brain Corp may suspend and / or terminate Customer’s access to the Beta Product(s).
      </Typography>
      <Typography gutterBottom>
      <b>10. Suspension of Services</b> Semantic Brain Corp may immediately suspend Customer or one of its User’s access to and use of the Beta Product(s) if Customer or one of its Users is in breach of Section 9, provided that the suspension will continue only for as long as reasonably necessary for Customer to remedy the breach.
      </Typography>
      <Typography gutterBottom>
      <b>11. Confidential Information</b> Customer acknowledges and agrees that participation in the Beta testing under this Agreement will result in Semantic Brain Corp disclosing certain confidential, proprietary and/or trade secret information related to the Beta Products and/or Semantic Brain Corp (the “Confidential Information”). Such Confidential Information includes, without limitation, the features, functionality and existence of the Beta Product(s), and any know how, trade secrets, computer programs, source code, flowcharts, diagrams, manuals, schematics, development tools, specifications, design documents, marketing information, financial information, business plans or reports made available to Customer. Customer agrees that it will not, without the express prior written consent of Semantic Brain Corp, disclose any Confidential Information or any part thereof to any third party, except to the extent that such Confidential Information (a) is or becomes generally available to the public through any means other than as a result of any act or omission by Customer; (b) is rightfully received by Customer from a third party that is not subject to any obligation of confidentiality with respect thereto and without limitation as to its use; or (c) is independently developed by Customer without any reliance on any Confidential Information. At the termination of this Agreement or at any time by request of Semantic Brain Corp, Customer will return all Confidential Information in its possession to Semantic Brain Corp and further agrees that it will not duplicate, translate, modify, copy, print, disassemble, decompile or otherwise tamper with the Beta Product(s) or any Confidential Information.
      </Typography>
      <Typography gutterBottom>
      <b>12. Third Party Tool Integrations</b> If applicable, one or more Beta Products may integrate with third party services. Beta Customer hereby consents to the sharing of the information in the Beta Products with these third party services and certifies that it has any and all required consents for doing so.
      </Typography>
      <Typography gutterBottom>
      <b>13. Disclaimer of Warranties</b> THE BETA PRODUCT(S) ARE PROVIDED “AS IS”. SEMANTIC BRAIN CORP MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE BETA PRODUCT(S), INCLUDING ANY REPRESENTATION THAT THE SERVICES THEREUNDER WILL BE UNINTERRUPTED OR ERROR-FREE. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, SEMANTIC BRAIN CORP DISCLAIMS ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE IN RESPECT OF THE BETA PRODUCT(S). FOR THE AVOIDANCE OF DOUBT, ALL BETA PRODUCT(S) ARE PRELEASE, ARE EXPECTED TO CONTAIN DEFECTS WHICH MAY BE MATERIAL, AND ARE NOT EXPECTED TO OPERATE AT THE LEVEL OF PERFORMANCE OR COMPATIBILITY OF A FINAL, GENERALLY AVAILABLE PRODUCT OFFERING. BETA PRODUCT(S) MAY NOT OPERATE ACCURATELY AND MAY BE SUBSTANTIALLY MODIFIED PRIOR TO PUBLIC AVAILABILITY OR WITHDRAWN AT ANY TIME. ACCORDINGLY, ACCESS TO AND USE OF THE BETA PRODUCT(S) IS ENTIRELY AT CUSTOMER’S OWN RISK. IN NO EVENT SHALL SEMANTIC BRAIN CORP BE LIABLE FOR ANY DAMAGE WHATSOEVER ARISING OUT OF THE USE OF OR INABILITY TO USE THE BETA PRODUCT(S), EVEN IF SEMANTIC BRAIN CORP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU ARE ADVISED TO SAFEGUARD IMPORTANT DATA, TO USE CAUTION AND NOT TO RELY IN ANY WAY ON THE CORRECT FUNCTIONING OR PERFORMANCE OF ANY BETA PRODUCT.
      </Typography>
      <Typography gutterBottom>
      <b>14. Indemnity</b> Customer agrees to indemnify and hold Semantic Brain Corp, its officers, directors and employees harmless from any losses (including attorneys’ fees) that result from any third party claims related to Customer’s (or its Users) access, use or misuse of the Beta Product(s), or any act or omission by Customer or its Users in violation of this Agreement.
      </Typography>
      <Typography gutterBottom>
      <b>15. Dispute Resolution</b> Governing Law and Venue. The parties will attempt to resolve any dispute related to this Agreement through good faith, informal negotiation. If initial negotiation does not resolve the dispute, the parties will select a mutually agreed mediator in a mutually agreed location to attempt to resolve the dispute. If mediation fails to resolve the dispute, either party may file an action in a provincial court in Ontario, Canada only and each party irrevocably submits to the jurisdiction and venue of the applicable courts. The laws of the Province of Ontario govern this Agreement. The prevailing party in any litigation may seek to recover its legal fees and costs. Any breach of confidentiality obligations in this Agreement, or any unauthorized use of the services or a party’s intellectual property by the other, may cause irreparable harm. In no event may this agreement be governed by the United Nations convention on contracts for the international sale of goods.
      </Typography>
      <Typography gutterBottom>
      <b>16. Miscellaneous</b> This Agreement does not create a partnership, agency relationship, or joint venture between the parties. Any assignment of this Agreement by Customer in whole or in part without Semantic Brain Corp’s prior written consent will be null and void, except an assignment to a successor that is not a competitor of Semantic Brain Corp’s made in connection with a merger or sale of all or substantially all of Customer’s assets or stock or to an Affiliate. If this Agreement is translated into a language other than English, the translation is for convenience only, and the English language version will govern. If any provision of this Agreement is unenforceable, that provision will be modified to render it enforceable to the extent possible to affect the parties’ intention and the remaining provisions will not be affected. Failure of Semantic Brain Corp to enforce a right under this Agreement shall not act as a waiver of that right or the ability to later assert that right relative to the particular situation involved. This Agreement includes any schedules and exhibits attached hereto. Such documents encompass the entire agreement between Customer and Semantic Brain Corp with respect to the subject matter hereof and supersede all prior representations, agreements and understandings, written or oral. This Agreement may only be altered, amended or modified by duly executed written instrument. 
      You may not assign this Agreement without the prior written consent of Semantic Brain Corp. Subject to the foregoing, the Agreement shall be binding upon the parties and their respective administrators, successors and assigns.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => window.print()} color="primary">
        Print
      </Button>
      <Button autoFocus onClick={handleCloseTandC} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
    );
}

//All notices to be provided by Semantic Brain Corp to Customer under this Agreement may be delivered in writing (a) by nationally recognized overnight delivery service (“Courier”) or US mail to the contact mailing address provided by Customer on any form; or (b) electronic mail to the electronic mail address provided for Customer’s Existing Account owner. Customer must give notice to Semantic Brain Corp in writing by Courier or US Mail to the following address: Semantic Brain Corp, Inc., Attn: Legal Department, 2111 Mission St., 4th Floor, San Francisco, CA 94110 USA. All notices shall be deemed to have been given immediately upon delivery by electronic mail, or if otherwise delivered upon receipt or, if earlier, two (2) business days after being deposited in the mail or with a Courier as permitted above. 