import { styled } from "@mui/material/styles";
import { CSVLink } from "react-csv";

import {
  Button,
  RadioGroup,
  Select,
  TableHead,
  TableCell,
} from "@mui/material";


import Item from "./Item";
import DateSelector from "./DateSelector";
import configData from "../../config.json";

export const ItemOuterDiv = styled(Item)({
  textAlign: "center",
});

export const DateSelectorInnerDiv = styled(DateSelector)({
  display: "inline-block",
  margin: "0 auto",
  padding: "3px",
});

export const CSVLinkInnerDiv = styled(CSVLink)({
  display: "inline-block",
  margin: "0 auto",
  padding: "3px",
});

export const RadioGroupInnerDiv = styled(RadioGroup)({
  display: "inline-block",
  margin: "0 auto",
  padding: "3px",
});

export const SelectInnerDiv = styled(Select)({
  display: "inline-block",
  margin: "0 auto",
  padding: "3px",
});

export const StyledTableHead = styled(TableHead)({
  backgroundColor: configData.Constants.BG_GREY,
});
export const SmallTableCell = styled(TableCell)({
  fontSize: "0.775rem"
});


export const TableCellVertDivider = styled(TableCell)({
  borderRight: "1px solid black",
});

export const ButtonInnerDiv = styled(Button)({
  display: "inline-block",
  margin: "0 auto",
  padding: "3px",
  marginRight: "10px",
  marginLeft: "10px",
});