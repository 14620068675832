import React from "react";
import axios from "axios";

import configData from "../../config.json";
import { UserContext } from "../../Context";

let ERROR_URL = configData.baseNTFCURL + "/ntfc/v2/error";
export default class ErrorBoundary extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  async logError(error, errorInfo) {
    // log the error to the error reporting service
    try {
      await axios
        .post(ERROR_URL, {
          sid: this.context[0]?.selectedSID,
          error: error,
          errorInfo: errorInfo,
        })
        .then(function (response) {
          console.log(response);
        });
    } catch (e) {
      console.log(e);
    }
  }

  componentDidCatch(error, errorInfo) {
    this.logError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
