import React from "react";
import { IconButton, Tooltip } from "@mui/material";

import HelpIcon from "@mui/icons-material/Help";


export default function HelpTooltip({ text, label }) {

  return (
    <Tooltip title={<React.Fragment>{text}</React.Fragment>} placement="right-start">
      <IconButton>
        <HelpIcon color='primary'/>
        {label ? <div>{label}</div> : null}
      </IconButton>
    </Tooltip>
  );
}