import React, { useEffect, useState } from "react";

import { Button, TextField, Grid, Paper, Typography } from "@mui/material";

import "../../styles.css";

export default function PwdChangeForm(props) {
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword0, setNewPassword0] = useState("");
  const [newPassword1, _setNewPassword1] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);

  const setNewPassword1 = (value) => {
    if (value === newPassword0) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
    _setNewPassword1(value);
  };

  useEffect(() => {
    if (
      username.length > 5 &&
      password.length > 5 &&
      newPassword0.length > 5 &&
      newPassword1.length > 5 &&
      newPassword0 === newPassword1
    )
      setSubmitEnabled(true);
    else setSubmitEnabled(false);
  }, [username, password, newPassword0, newPassword1]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setPassword("");
    setNewPassword0("");
    setNewPassword1("");
    props.ChangePwdFunc(username, password, newPassword0);
  };

  const genChangePwdForm = () => {
    return (
      <Grid container spacing={0} justifyContent="center" direction="row">
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={2}
            className="login-form"
          >
            <Paper
              variant="elevation"
              elevation={2}
              className="login-background"
            >
              <Grid item>
                <Typography component="h1" variant="h5">
                  Change password
                </Typography>
              </Grid>
              <Grid item>
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        type="email"
                        placeholder="Email"
                        fullWidth
                        name="username"
                        variant="filled"
                        value={username}
                        autoComplete="on"
                        onChange={(event) => setUsername(event.target.value)}
                        required
                        autoFocus
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        type="password"
                        placeholder="Current Password"
                        fullWidth
                        name="old password"
                        variant="filled"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        type="password"
                        placeholder="New Password"
                        fullWidth
                        name="new password 0"
                        variant="filled"
                        value={newPassword0}
                        onChange={(event) =>
                          setNewPassword0(event.target.value)
                        }
                        required
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        error={!passwordMatch}
                        helperText={
                          passwordMatch ? "" : "Passwords must match."
                        }
                        type="password"
                        placeholder="Repeat New Password"
                        fullWidth
                        name="new password 1"
                        variant="filled"
                        value={newPassword1}
                        onChange={(event) =>
                          setNewPassword1(event.target.value)
                        }
                        required
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="button-block"
                        disabled={!submitEnabled}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return genChangePwdForm();
}
