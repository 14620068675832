import { generatorApiInstance } from "./GPTApiInstance";

const genText = (selection, audience, tone, keywords, topic, description) =>
  generatorApiInstance.post("/gentext", {
    selection: selection,
    audience: audience,
    tone: tone,
    keywords: keywords,
    topic: topic,
    description: description,
  });
const adjustText = (text, operation, context, hint) =>
  generatorApiInstance.post("/adjusttext", {
    text: text,
    operation: operation,
    context: context,
    hint:  hint
  });
const getKeywords = (url) =>
  generatorApiInstance.post("/getkeywords", { url: url });
const genEmail = (
  sender,
  sender_company,
  recipient,
  recipient_company,
  subject,
  description,
  tone,
  style
) =>
  generatorApiInstance.post("/genemail", {
    sender: sender,
    sender_company: sender_company,
    recipient: recipient,
    recipient_company: recipient_company,
    subject: subject,
    description: description,
    tone: tone,
    style: style,
  });

const genIdea = (params) => generatorApiInstance.post("/genIdea", params);
const genSections = (params) =>
  generatorApiInstance.post("/genSections", params);
const genSectionPoints = (params) =>
  generatorApiInstance.post("/genSectionPoints", params);
const genSectionBody = (params) =>
  generatorApiInstance.post("/genSectionBody", params);
const genBySample = (params) =>
  generatorApiInstance.post("/genBySample", params);

const emotion = (text, keyword) =>
  generatorApiInstance.post("/emotion", {
    text: text,
    keyword: keyword,
  });
const topics = (text) => generatorApiInstance.post("/topics", { text: text });
const seo = (html) => generatorApiInstance.post("/seo", { text: html });

const serptest = (text) =>
  generatorApiInstance.post("/serptest", {
    text: text,
  });

//Used to prime the cache, doesn't wait for result
const serp = (text) => generatorApiInstance.get("/serp/" + text);

//Combines the two above in a single call
const serpSeo = (text, keyword) =>
  generatorApiInstance.post("/serp_seo", {
    text: text,
    keyword: keyword,
  });

const expose = {
  adjustText,
  emotion,
  genText,
  getKeywords,
  genEmail,
  genIdea,
  genSections,
  genSectionPoints,
  genSectionBody,
  genBySample,
  seo,
  serp,
  serptest,
  serpSeo,
  topics,
};
export default expose;
