import { handleSave } from "../../../util/textutil";

const icon = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lessformal" viewBox="0 0 20 20">
<path fill-rule="evenodd" d="M8.207 13.293L7.5 14a5.5 5.5 0 110-11h5a5.5 5.5 0 110 11s-1.807 2.169-4.193 2.818C7.887 16.933 7.449 17 7 17c.291-.389.488-.74.617-1.052C8.149 14.649 7.5 14 7.5 14c.707-.707.708-.707.708-.706h.001l.002.003.004.004.01.01a1.184 1.184 0 01.074.084c.039.047.085.108.134.183.097.15.206.36.284.626.114.386.154.855.047 1.394.717-.313 1.37-.765 1.895-1.201a10.266 10.266 0 001.013-.969l.05-.056.01-.012m0 0A1 1 0 0112.5 13a4.5 4.5 0 100-9h-5a4.5 4.5 0 000 9 1 1 0 01.707.293" clip-rule="evenodd"/> 
</svg>`;

export default class TextEditorCasual {
  constructor({ api, data, config, block }) {
    this.data = data;
    this.api = api;
    this.config = config;
    this.block = block;
  }

  render() {
    if (this.block.name !== "paragraph") {
      const wrapper = document.createElement("div");
      return wrapper;
    }
    return {
      label: "Less Formal",
      icon: icon,
      onActivate: () => {
        const savePromise = this.block.save();
        savePromise.then((value) =>
          handleSave(this.config, this.api, this.block, value, "less formal")
        );
      },
    };
  }
  static get isTune() {
    return true;
  }
}
