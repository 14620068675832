import React from "react";
import { Tooltip } from "@mui/material";

export default function LengthTooltip({ text, length }) {

  return (
    text && text.length > length ? (
      <Tooltip title={text} placement="top-end">
        <div>{text.slice(0, length-2) + "..."}</div>
      </Tooltip>
    ) : (
      <div>{text}</div>
    )
    );
}