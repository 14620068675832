import React from "react";
import { Tooltip } from "@mui/material";

export default function HoverTooltip({ text, help }) {
  return (
      <Tooltip title={help} placement="top-end">
        <div>{text}</div>
      </Tooltip>
    )

}