import { useState } from "react";
import { makeStyles } from "@mui/styles";

import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";

import { red } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import { Add } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  section: {
    fontSize: 18,
  },
  point: {
    fontSize: 12,
  },
}));

export default function SectionEditorTextBox({
  text,
  textType, //[S]ection title or talking [P]oint text
  mode, //[S]ections page or [P]oints page
  sectionIndex,
  pointIndex,
  changeCallback,
  addRemoveCallback,
}) {
  const classes = useStyles();

  const [str, setStr] = useState(text);

  const onChange = (event) => {
    setStr(event.target.value);
    changeCallback(sectionIndex, pointIndex, event.target.value);
  };
  return (
    <>
      {textType === "S" ? (
        <TextField
          fullWidth
          value={str}
          onChange={onChange}
          variant="standard"
          size="small"
          InputProps={{
            classes: {
              input: classes.section,
            },
            endAdornment: (
              <InputAdornment position="end">
                {mode === "S" ? (
                  <Tooltip title="Delete">
                    <IconButton
                      edge="end"
                      sx={{ color: red[200] }}
                      onClick={() =>
                        addRemoveCallback("DELETE", sectionIndex, pointIndex)
                      }
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
                <Tooltip
                  title={mode === "S" ? "Add Section" : "Add Talking Point"}
                >
                  <IconButton
                    edge="end"
                    onClick={() =>
                      addRemoveCallback("ADD", sectionIndex, pointIndex)
                    }
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <TextField
          fullWidth
          value={str}
          onChange={onChange}
          variant="standard"
          size="small"
          InputProps={{
            classes: {
              input: classes.point,
            },
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Delete">
                  <IconButton
                    edge="end"
                    sx={{ color: red[200] }}
                    onClick={() =>
                      addRemoveCallback("DELETE", sectionIndex, pointIndex)
                    }
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add Talking  Point">
                  <IconButton
                    edge="end"
                    onClick={() =>
                      addRemoveCallback("ADD", sectionIndex, pointIndex)
                    }
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      )}
    </>
  );
}
