import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Box, Step, Stepper, StepLabel } from "@mui/material";

import TextGeneratorStep0 from "./TextGeneratorStep0";
import TextGeneratorStep1 from "./TextGeneratorStep1";
import TextGeneratorStep2 from "./TextGeneratorStep2";
import TextGeneratorStep3 from "./TextGeneratorStep3";
import AlertDialog from "../widgets/AlertDialog";
import Spinner from "../widgets/Spinner";

import { TextGeneratorContext, UserContext } from "../../Context";
import textApi from "../../api/gpt";
import useApi from "../../hooks/useApi";

import "../../styles.css";
import { augmentErrorTryAgain } from "../../util/errorutil";

const steps = ["Topic/Title", "Sections", "Talking points", "Content"];

export default function MultiStepTextGenerator() {
  const history = useHistory();

  const [userContext, setUserContext] = useContext(UserContext);
  const [formContext, setFormContext] = useState(null);

  const getKeywords = useApi(textApi.getKeywords);

  const [activeStep, setActiveStep] = React.useState(0);
  const [formState, setFormState] = React.useState({
    type: "blog",
    audience: "CMO",
    tone: "formal",
    titleKeyword: "",
    keywords: [],
    siteKeywords: [],
    topic: "",
    description: "",

    title: "",
    generatedTitles: [],
    sections: [],
    sectionPoints: [],
    text: "", //TODO - text format? editor block?
  });

  const [busy, _setBusy] = useState(false);
  const [dialogText, setDialogText] = useState({ title: "", text: "" });
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = () => {
    if(!userContext?.selectedClient){
      history.push("/agencyClients");
    }
    setDialogOpen(false);
  };

  const setBusy = (flag, error) => {
    _setBusy(flag);
    if (error) {
      setDialogText({ title: "Error", text: error });
      setDialogOpen(true);
    }
  };
  const handleNext = () => {
    if (activeStep === steps.length - 1) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) return;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const setSiteKeywords = (value) => {
    setFormContext({...formContext, siteKeywords: value})
  }
  const setInitialFormContext = () => {
    setFormContext({
      seoOptimized: false,
      seoTargetKeyword: null,
      type: "blog",
      audience: "CMO",
      tone: "formal",
      titleKeyword: "",
      keywords: [],
      siteKeywords: [],
      topic: "",
      description: "",
  
      title: "",
      generatedTitles: [],
      sections: [],
      sectionPoints: [],
      text: "",
      textBlocks: [],
    });
  }
  useEffect(() => {
    if (!userContext?.selectedClient) {
      setDialogText({
        title: "Error",
        text: "No Client Selected",
      });
      setDialogOpen(true);
    } else {
      setInitialFormContext();
      setBusy(true);
      getKeywords.request(userContext?.selectedURL);
    }
  }, []);

  useEffect(() => {
    if (getKeywords.data) {
      setBusy(false);
      setSiteKeywords(getKeywords.data.data);
    }
  }, [getKeywords.data]);
  useEffect(() => {
    if (getKeywords.error && getKeywords.error !== "") {
      let errorText = augmentErrorTryAgain("Error getting keywords.", getKeywords.error)
      setBusy(false);
      setDialogText({ title: "Error", text: errorText });
      setDialogOpen(true);
    }
  }, [getKeywords.error]);

  // const formStateUpdate = (val) => {
  //   setFormState(val);
  // };
  // const setupSelectionCallback = (value) => {
  //   setFormState({
  //     ...formState,
  //     type: value.type,
  //     audience: value.audience,
  //     tone: value.tone,
  //     titleKeyword: value.titleKeyword,
  //     topic: value.topic,
  //     description: value.description,
  //   });
  // };
  // const titleSelectionCallback = (value) => {
  //   console.log("back in main componsent, set title", value);
  //   if (activeStep === 0) setFormState({ ...formState, title: value });
  // };
  // const generatedTitlesSelectionCallback = (value) => {
  //   if (activeStep === 0)
  //     setFormState({ ...formState, generatedTitles: [...value] });
  // };
  // const sectionsCallback = (value) => {
  //   console.log("back in main componsent, set sections", value);
  //   if (activeStep === 1) setFormState({ ...formState, sections: [...value] });
  // };
  // const sectionPointsCallback = (value) => {
  //   console.log("back in main componsent, set section points", value);
  //   if (activeStep === 2)
  //     setFormState({ ...formState, sectionPoints: [...value] });
  // };
  // const textCallback = (value) => {
  //   console.log("back in main componsent, set text", value);
  //   if (activeStep === 3) setFormState({ ...formState, text: value });
  // };
  // const keywordsCallback = (value) => {
  //   console.log("back in main componsent, set keywords", value);
  //   if (activeStep === 1) setFormState({ ...formState, keywords: [...value] });
  // };

  return (
    <TextGeneratorContext.Provider value={[formContext, setFormContext]}>
      <Box sx={{ width: "100%" }}>
        <Stepper className="center50" activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 ? (
          <TextGeneratorStep0
            handleBack={handleBack}
            handleNext={handleNext}
            busyCallback={setBusy}
          />
        ) : null}
        {activeStep === 1 ? (
          <TextGeneratorStep1
            handleBack={handleBack}
            handleNext={handleNext}
            busyCallback={setBusy}
          />
        ) : null}
        {activeStep === 2 ? (
          <TextGeneratorStep2
            handleBack={handleBack}
            handleNext={handleNext}
            busyCallback={setBusy}
          />
        ) : null}
        {activeStep === 3 ? (
          <TextGeneratorStep3
            handleBack={handleBack}
            handleNext={handleNext}
            busyCallback={setBusy}
          />
        ) : null}
      </Box>
      <AlertDialog
        dialogOpen={dialogOpen}
        title={dialogText.title}
        text={dialogText.text}
        callback={closeDialog}
      />

      <Spinner busy={busy} />
    </TextGeneratorContext.Provider>
  );
}
