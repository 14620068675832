import { handleSave } from "../../../util/textutil";

const icon = `
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px">
  <path d="M 20 4 C 14.507813 4 10 8.507813 10 14 L 10 31.75 L 7.125 28.875 L 4.3125 31.71875 L 12 39.40625 L 19.6875 31.71875 L 16.875 28.90625 L 14 31.75 L 14 14 C 14 10.691406 16.691406 8 20 8 L 31 8 L 31 4 Z M 38 10.59375 L 30.28125 18.3125 L 33.125 21.125 L 36 18.25 L 36 36 C 36 39.308594 33.308594 42 30 42 L 19 42 L 19 46 L 30 46 C 35.492188 46 40 41.492188 40 36 L 40 18.25 L 42.875 21.125 L 45.6875 18.28125 Z"/>
</svg>
`;


export default class TextEditorRephrase {
  constructor({ api, data, config, block }) {
    this.data = data;
    this.api = api;
    this.config = config;
    this.block = block;
  }

  render() {
    if (this.block.name !== "paragraph") {
      const wrapper = document.createElement("div");
      return wrapper;
    }
    return {
      label: "Rephrase",
      icon: icon,
      onActivate: () => {
        // let block = this.api.blocks.getBlockByIndex(this.api.blocks.getCurrentBlockIndex())
        let hint = prompt("Rephrase hints", "");
        if (hint === null) {
          //User cancelled the prompt.
          return
        }

        const savePromise = this.block.save();
        savePromise.then((value) =>
          handleSave(this.config, this.api, this.block, value, "rephrase", hint)
        );
      },
    };
  }
  static get isTune() {
    return true;
  }
}
