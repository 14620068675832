// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';

// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//       <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import GA4React from 'ga-4-react';
import configData from "./config.json";

const ga4react = new GA4React(configData.GA_TAG);

const app = (
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

ga4react.initialize()
  .then(() => configData.GA_TRACKING_BLOCKED = false)
  .catch(() => configData.GA_TRACKING_BLOCKED = true)
  // eslint-disable-next-line react/no-render-return-value
  .finally(() => ReactDOM.render(app, document.getElementById("root")));

  





