import React, { useEffect, useState } from "react";
import {  TextField, Grid, Paper, Typography } from "@mui/material";

import "../../styles.css";

import { ItemOuterDiv, ButtonInnerDiv} from "../widgets/StyledWidgets";

export default function ForgotPwdForm(props) {
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [username0, setUsername0] = useState("");
  const [username1, setUsername1] = useState("");

  useEffect(() => {
    if (username0.length > 5 && username0 === username1) setSubmitEnabled(true);
    else setSubmitEnabled(false);
  }, [username0, username1]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setUsername0("");
    setUsername1("");
    props.ForgotPwdFunc(username0);
  };

  const handleCancel = (evt) => {
    evt.preventDefault();
    setUsername0("");
    setUsername1("");
    props.ShowForgotPwdFunc(false);
  };

  const genForgotPwdForm = () => {
    return (
      <Grid container spacing={0} justifyContent="center" direction="row">
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={2}
            className="login-form"
          >
            <Paper
              variant="elevation"
              elevation={2}
              className="login-background"
            >
              <Grid item>
                <Typography component="h1" variant="h5">
                  Email Address
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField
                      type="email"
                      placeholder="Email"
                      fullWidth
                      name="username0"
                      variant="filled"
                      value={username0}
                      autoComplete="on"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => setUsername0(event.target.value)}
                      required
                      autoFocus
                    />
                    <TextField
                      type="email"
                      placeholder="Repeat Email"
                      fullWidth
                      name="username1"
                      variant="filled"
                      value={username1}
                      autoComplete="on"
                      onChange={(event) => setUsername1(event.target.value)}
                      required
                      autoFocus
                    />
                  </Grid>

                  <ItemOuterDiv>
                    <ButtonInnerDiv
                      variant="contained"
                      color="primary"
                      disabled={!submitEnabled}
                      onClick={handleSubmit}
                    >
                      Submit
                    </ButtonInnerDiv>
                    <ButtonInnerDiv
                      variant="contained"
                      color="primary"
                      onClick={handleCancel}
                    >
                      Cancel
                    </ButtonInnerDiv>
                  </ItemOuterDiv>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return genForgotPwdForm();
}
