import { Grid } from "@mui/material";
import { useState } from "react";
import Item from "../../widgets/Item";

import TextEditor from "./TextEditor";
import TextEditorToolbar from "../ToolbarComponents/TextEditorToolbar";
import BorderlessItem from "../../widgets/BorderlessItem";

var title=''
var description = ''
export default function TextEditorWrapper({ id, context, text, seo, seoTargetKeyword, docType, model, callback, errorCallback, busyCallback, additionalParameters }) {

  const [rawText, setRawText] = useState("");
  const [htmlText, setHtmlText] = useState("");
  // const [title, setTitle] = useState("");
  // const [description, setDescription] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [textRequest, setTextRequest] = useState(false);
  const [dataAvailable, setDataAvailable] = useState(false);

  const setDescription = (t) => {
    console.log("setD", t)
    description = t
  }
  const setTitle = (t) => {
    console.log("setT", t)
    title = t
  }
  const localCallback = (text) => {
    setRawText(text)
    setDataAvailable(true)
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Item>
            <TextEditor
              id={id}
              context={context}
              text={text}
              seo={seo}
              docType={docType}
              model={model}
              callback={callback || localCallback}
              errorCallback={errorCallback}
              busyCallback={busyCallback}
              setBlocks={setBlocks}
              setRawText={setRawText}
              setHtmlText={setHtmlText}
              setTitle={setTitle}
              setDescription={setDescription}
              textRequest={textRequest}
              setTextRequest={setTextRequest}
              setDataAvailable={setDataAvailable}
            />
          </Item>
        </Grid>
        <Grid item xs={4}>
          <BorderlessItem>
            <TextEditorToolbar
              errorCallback={errorCallback}
              busyCallback={busyCallback}
              blocks={blocks}
              rawText={rawText}
              htmlText={htmlText}
              textRequest={textRequest}
              title={title}
              description={description}
              setTextRequest={setTextRequest}
              dataAvailable={dataAvailable}
              setDataAvailable={setDataAvailable}
              seoTargetKeyword={seoTargetKeyword}
            />
          </BorderlessItem>
        </Grid>
      </Grid>
    </>
  );
}
