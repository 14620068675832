import { makeid } from "./util";

const _createHeadingBlock = (text, level) => {
  return {
    id: makeid(10),
    type: "header",
    data: {
      text: text,
      level: level,
    },
  };
};
export const createSectionBlock = (text) => {
  return _createHeadingBlock(text, 2);
};
export const createTitleBlock = (text) => {
  return _createHeadingBlock(text, 1);
};
export const createTextBlock = (text) => {
  return {
    id: makeid(10),
    type: "paragraph",
    data: {
      text: text,
    },
  };
};
