export var jsonMenu = [
    /*
    {
        title: 'Notifications',
        tenant: 'SB',
        menuItems: [
            {
                label: 'Configuration',
                name: 'Configuration',
                route: '/notificationConfiguration',
                param: ''
            },
            {
                label: 'Analytics',
                name: 'Analytics',
                route: '/notificationsAnalytics',
                param: ''
            },
            {
                label: 'Geography',
                name: 'Geography',
                route: '/notificationGeography',
                param: ''
            },
        ]
    },
    {
        title: 'Marketing Campaigns',
        tenant: 'SB',
        menuItems: [
            {
                label: 'Create Campaign',
                name: 'Create Campaign',
                route: '/createCampaign',
                param: ''
            },
            {
                label: 'Upload Contacts',
                name: 'Upload Contacts',
                route: '/uploadContacts',
                param: ''
            },
            {
                label: 'Manual Entry',
                name: 'Manual Entry',
                route: '/createContact',
                param: ''
            },
        ]
    },
    */
/*
    {
        title: 'Sales',
        tenant: 'SB',
        role: 'b2b_user',
        menuItems: [
            {
                label: 'Customer Communication Orig',
                name: 'Customer Communication Orig',
                route: '/customerCommunicationNoTemplate',
                param: '',
                role: 'b2b_demo_user',
            },
            {
                label: 'Customer Communication',
                name: 'Customer Communication',
                route: '/customerCommunication',
                param: '',
            },
            {
                label: 'View Customer Communication',
                name: 'View Customer Communication',
                route: '/viewCustomerCommunication',
                param: ''
            },
            {
                label: '⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯',
                name: '⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯',
            },
            {
                label: 'Communication Templates',
                name: 'Communication Templates',
                route: '/communicationTemplates',
                param: '',
            },
            {
                label: '⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯ ',
                name: '⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯ ',
            },
            {
                label: 'View Campaigns',
                name: 'View Campaigns',
                route: '/viewSalesCampaigns',
                param: ''
            },
            {
                label: 'Create Campaign',
                name: 'Create Campaign',
                route: '/createSalesCampaign',
                param: ''
            },
            {
                label: 'Upload Contacts',
                name: 'Upload Contacts',
                route: '/uploadSalesContacts',
                param: ''
            },
            {
                label: 'Manual Contact Entry',
                name: 'Manual Contact Entry',
                route: '/createSalesContact',
                param: ''
            },
        ]
    },
    */
   /*
    {
        title: 'Marketing',
        tenant: 'SB',
        role: 'b2b_user',
        menuItems: [  
            {
                label: 'Campaign',
                tenant: 'SB',
                route: '/marketing',
            },        
            {
                title: 'Optimization',
                label: 'Optimization',
                role: 'b2b_demo_user',
                route: '/marketingOptimization',
            },        
        ]
    },
    */
   /*
    {
        title: 'Notifications',
        tenant: 'SB',
        role: 'mkt_user',
        menuItems: [
            {
                label: 'Analytics',
                route: '/notificationsAnalytics',
                param: ''
            },
        ]
    },
    */
   /*
    {
        title: 'Analysis',
        tenant: 'SB',
        role: 'b2b_user',
        menuItems: [
            {
                label: 'All Access',
                name: 'All Access',
                route: '/accessAnalytics',
                param: ''
            },
            
            // {
            //     label: 'Marketing Campaign',
            //     name: 'Marketing Campaign',
            //     route: '/marketingAnalytics',
            //     param: ''
            // },
            
            {
                label: 'Sales Campaign',
                name: 'Sales Campaign ',
                route: '/salesAnalytics',
                param: ''
            },
            {
                label: 'Most Recent Communications',
                name: 'Most Recent Communications',
                route: '/communicationsByAccount',
                param: '',
            },
            {
                label: 'Stats By Communications',
                name: 'Stats By Communications',
                route: '/statsByCommunications',
                param: '',
            },
            //TEMP - PATCH
            {
                label: 'Admin - Patch old records',
                name: 'Admin - Patch old records',
                route: '/patchAnalytics',
                param: '',
                role: 'b2b_demo_user'
            },
        ]
    },
    */
   /*
    {
        title: 'Web Site',
        tenant: 'SB',
        role: 'b2b_user',
        menuItems: [  
            {
                label: 'Report',
                tenant: 'SB',
                route: '/webSiteReport',
            },
        ]
    },
    */
    {
        title: 'Tools',
        tenant: 'SB',
        role: 'b2b_user',
        menuItems: [  
            // {
            //     label: 'Text Generator',
            //     tenant: 'SB',
            //     route: '/textGenerator',
            // },
            // {
            //     label: 'Generate by Topic',
            //     tenant: 'SB',
            //     route: '/genByTopic',
            // },
            // {
            //     label: 'Expand Text',
            //     tenant: 'SB',
            //     route: '/genBySample',
            // },
            {
                label: 'Email Generator',
                tenant: 'SB',
                route: '/emailGenerator',
            },
            // {
            //     label: 'Social Generator',
            //     tenant: 'SB',
            //     route: '/socialGenerator',
            // },
            {
                label: 'Multi-Step Editor',
                tenant: 'SB',
                route: '/multi',
            },
            {
                label: 'Editor Demo',
                tenant: 'SB',
                route: '/editor',
            },
        ]
    },
    {
        title: 'Agency',
        tenant: 'SB',
        role: 'agency_user',
        menuItems: [  
            {
                label: 'Clients',
                tenant: 'SB',
                route: '/agencyClients',
            },
        ]
    },
    {
        title: 'Help',
        tenant: 'SB',
        role: 'b2b_user',
        menuItems: [
            {
                label: 'Site ID',
                name: 'Site ID',
                route: '/showSID',
                param: ''
            },
            {
                label: 'User Guide',
                name: 'User Guide',
                route: '/userGuide',
                param: ''
            },
            {
                label: '⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯',
                name: '⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯',
            },
            {
                label: 'Analytics Help',
                name: 'Analytics Help',
                route: '/analyticsHelp',
                param: ''
            },
            {
                label: 'Privacy Help',
                name: 'Privacy Help',
                route: '/privacyHelp',
                param: ''
            },
            {
                label: '⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯ ',
                name: '⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯ ',
            },
            {
                label: 'Setup Help',
                name: 'Setup Help',
                route: '/setupHelp',
                param: ''
            },
            {
                label: 'Implementation Notes',
                name: 'Implementation Notes',
                route: '/implementation',
                param: ''
            },
            /*
            {
                label: 'Marketing Help',
                name: 'Marketing Help',
                route: '/marketingHelp',
                param: ''
            },
            */
        ]
    },
]

