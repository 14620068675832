import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import GeneratorSetup from "./TextComponents/GeneratorSetup";

import textApi from "../../api/gpt";
import useApi from "../../hooks/useApi";
import { TextGeneratorContext } from "../../Context";

import { cleanupPrefixed, splitByNumbers } from "../../util/textutil";
import Item from "../widgets/Item";
import { StyledTableHead } from "../widgets/StyledWidgets";

import SectionEditorTextBox from "./SectionEditorTextBox";
import { makeid } from "../../util/util";

export default function SectionEditor({
  sections,
  sectionPoints,
  changeCallback,
  addRemoveCallback
}) {
  const changeSection = (sectionIndex, pointIndex, value) => {
    changeCallback(sectionIndex, pointIndex, value);
  };

  const genKey = (val) => {
    if(val && val.length>0)
    return val
    else
    return makeid(10)

  }
  const generateSectionRow = (sectionEntry, sectionIndex) => {
    return (
      <>
        <TableRow key={sectionEntry}>
          <TableCell component="th" scope="row">
            <SectionEditorTextBox
              text={sectionEntry}
              textType={"S"}
              mode={sectionPoints === null ? 'S' : 'P'}
              sectionIndex={sectionIndex}
              pointIndex={null}
              changeCallback={changeSection}
              addRemoveCallback={addRemoveCallback}
              />
          </TableCell>
        </TableRow>
        {sectionPoints !== null ? (
          <>
            {sectionPoints[sectionIndex].map((pointEntry, pointIndex) => {
              return (
                <TableRow key={genKey(pointEntry)}>
                  <TableCell component="th" scope="row">
                    <SectionEditorTextBox
                      text={pointEntry}
                      textType={"P"}
                      mode={'P'}
                      sectionIndex={sectionIndex}
                      pointIndex={pointIndex}
                      changeCallback={changeSection}
                      addRemoveCallback={addRemoveCallback}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        ) : null}
      </>
    );
  };
  const generateTableSections = () => {
    return (
      <TableContainer component={Paper} style={{ width: "100%" }}>
        <Table size="small" aria-label="simple table">
          <TableBody>
            {sections.map((entry, index) => {
              return generateSectionRow(entry, index);
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return <>{generateTableSections()}</>;
}
