import { useEffect, useState } from "react";

import { Autocomplete, Chip, TextField } from "@mui/material";

export default function AutocompleteControlled({
  selection,
  keywords,
  label,
  callback,
  multiple,
}) {
  const [value, _setValue] = useState(selection);

  const setValue = (val) => {
    if (multiple) _setValue([...val]);
    else _setValue(val);
    callback(val);
  };
  useEffect(() => {
    if (selection === null || selection.length === 0) _setValue([]);
  }, [selection]);

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      id="tags-filled"
      options={keywords}
      freeSolo
      multiple={multiple}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={label}
          placeholder="Search"
        />
      )}
    />
  );
}
