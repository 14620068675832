import { format } from 'date-fns'

export const customBase64 = (text) => {
  let encoded = btoa(text);
  return encoded.replace("/", "_");
};

export function trimString(s, length) {
  if (!s) return s;
  return s.length > length ? s.substring(0, length - 3) + "..." : s;
}

export const convertDate = (date) => {
  var myDate = new Date(date * 1000);
  return myDate.toLocaleString();
};

export const formatTS = (ts) => {
  if(ts)
    return format(new Date(ts), 'yy-MM-dd HH:mm')
  else return ''
};

export function containsObject(list, key, val) {
  for (let i = 0; i < list.length; i++) {
    if (list[i][key] && list[i][key] === val) {
      return true;
    }
  }
  return false;
}

export function insertAfter(list, elem, after) {
  var tmp_array = [];
  while (list.length) {
    var next = list.pop();
    if (next !== after) {
      tmp_array.push(next); /*from ww  w.  ja  va2  s .  c  o  m*/
    } else {
      list.push(next);
      break;
    }
  }
  list.push(elem);
  while (tmp_array.length) {
    list.push(tmp_array.pop());
  }
}

export function findObject(list, key, val, key2, val2) {
  for (let i = 0; i < list.length; i++) {
    if (list[i][key] && list[i][key] === val) {
      // console.log('found1')
      // console.log(list[i]['state'])
      if (!list[i]["state"][key2] && !val2) {
        return list[i];
      }
      if (list[i]["state"][key2] && list[i]["state"][key2] === val2) {
        return list[i];
      }
    }
  }
  return null;
}

export function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function formatDate(d) {
  const month = d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  return `${d.getFullYear()}-${month}-${day}`;
}

export function numberFormatter(num, digits) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export function formatNumber(num, digits) {
  return num.toFixed(digits).replace(/\.0000$/, "");
}

export function getContrastingTextColorString(rgb) {
  if (rgb.startsWith("rgb(")) {
    rgb = rgb.replace(/[^\d,]/g, "").split(",");
  } else {
    if (rgb.startsWith("#")) {
      rgb = rgb.substring(1);
    }
    var bigint = parseInt(rgb, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    rgb = [r, g, b];
  }
  return getContrastingTextColor(rgb[0], rgb[1], rgb[2]);
}

export function getContrastingTextColor(red, green, blue) {
  if (typeof red === "string") {
    red = parseInt(red);
    green = parseInt(green);
    blue = parseInt(blue);
  }
  if (red * 0.299 + green * 0.587 + blue * 0.114 > 186) return "#000000";
  else return "#ffffff";
}

export function openInNewTab(url) {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
}

// Compute the edit distance between the two given strings
export function getEditDistance(a, b) {
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  var matrix = [];

  // increment along the first column of each row
  var i;
  for (i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  // increment each column in the first row
  var j;
  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          Math.min(
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1
          )
        ); // deletion
      }
    }
  }

  return matrix[b.length][a.length];
}

export const parseCSV = (string) => {
  let newLine = "\n";
  if (string.indexOf("\r\n") > 0) newLine = "\r\n";
  const csvHeader = string.slice(0, string.indexOf(newLine)).split(",");
  const csvRows = string
    .slice(string.indexOf(newLine) + newLine.length)
    .split(newLine);
  return {'csvHeader': csvHeader, 'csvRows': csvRows}
}

export const generateFileName = (base, start, end, sid, client) => {
  return base + (client ? "-" + client : '') + "-" + sid + "-" + start + "-" + end + ".csv";
};

export const generateNoDateFileName = (base, sid, client) => {
  return base + (client ? "-" + client : '') + "-" + sid + ".csv";
};

export const unpack = (rows, key) => {
  return rows.map(function (row) {
    return row[key];
  });
};

export const validateEmail = (inputText) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // console.log(regex.test(inputText))
  if (regex.test(inputText)) {
      return true;
  } else {
    return false;
  }
};

export const patchTemplate = (template, url) => {
  let result = template.replace("{{URL}}", url);
  return result
}
export const augmentTemplate = (template, tokenName, token) => {
  var urlRegex = /(https?:\/\/[^ \r\n]*)/g;

  console.log(template)

  let result = template.match(urlRegex)
  result.forEach((url) => {
    let prefix = '?'
    if(url.includes('?')){
      prefix = '&'
    }
    template = template.replace(url, url + prefix + tokenName + '=' + token)
  })
  return template
}

//https://stackoverflow.com/questions/11456850/split-a-string-by-commas-but-ignore-commas-within-double-quotes-using-javascript/57121244#57121244
export const splitByComma = (string) => {
  return string.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);
}

export const replaceBetween = (origin, startIndex, endIndex, insertion) => {
  return origin.substring(0, startIndex) + insertion + origin.substring(endIndex);
}