import { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,  
} from '@mui/material';

import "../../styles.css";
import TnC from "./TnC"
import TokenStorage from "../../api/TokenStorage";
const tokenStorageService = TokenStorage.getService();

export default function LoginForm(props) {

  const [tandC, setTandC] = useState(false);
  const [showTandC, setShowTandC] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');


  useEffect(() => {
    let saved_user = tokenStorageService.getSBUser();
    if(saved_user !== null){
      setUsername(saved_user)
      setTandC(true)
    }
  }, []);
  useEffect(() => {
    let saved_user = tokenStorageService.getSBUser();
    if(saved_user !== null && saved_user !== username ){
      setTandC(false)
    } else if(saved_user !== null && saved_user === username ){
      setTandC(true)
    }
  }, [username]);

  useEffect(() => {
    if (username.length > 5 && password.length > 5 && tandC === true)
      setSubmitEnabled(true);
    else
      setSubmitEnabled(false);
  }, [username, password, tandC]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setPassword("");
    props.LoginFunc(username, password);
  };

  const handleForgotPwd = (evt) => {
    evt.preventDefault();
    setUsername("");
    setPassword("");
    props.ShowForgotPwdFunc(true);
  };

  const handleRegister = (evt) => {
    evt.preventDefault();
    setUsername("");
    setPassword("");
    props.ShowRegisterFunc();
  };

  const handleTandC = (evt) => {
    // evt.preventDefault();
    setTandC(!tandC);
  };

  const handleShowTandC = (evt) => {
    setShowTandC(true)
  };
  const handleCloseTandC = () => {
    setShowTandC(false);
  };
  
  const genLoginForm = () => {
    return (
      <div>
        <Grid container spacing={0} justifyContent="center" direction="row">
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="center"
              spacing={2}
              className="login-form"
            >
              <Paper
                variant="elevation"
                elevation={2}
                className="login-background"
              >
                <Grid item>
                  <Typography component="h1" variant="h5">
                    Sign in
                  </Typography>
                </Grid>
                <Grid item>
                  <form onSubmit={handleSubmit}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <TextField
                          type="email"
                          placeholder="Email"
                          fullWidth
                          name="username"
                          variant="filled"
                          value={username}
                          autoComplete="on"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event) => setUsername(event.target.value)}
                          required
                          autoFocus
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          type="password"
                          placeholder="Password"
                          fullWidth
                          name="password"
                          variant="filled"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}                
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button-block"
                          disabled={!submitEnabled}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tandC}
                          onChange={handleTandC}
                          name="tandc"
                          color="primary"
                        />
                      }
                      label="Accept Terms and Conditions"
                    />
                  </form>
                </Grid>
                <Grid item>
                  <Link variant="body2" onClick={handleShowTandC}>
                    Show Terms and Conditions
                  </Link>
                </Grid>
                <Grid item>
                  <Link variant="body2" onClick={handleForgotPwd}>
                    Forgot Password
                  </Link>
                </Grid>
                <Grid item>
                  <Link variant="body2" onClick={handleRegister}>
                    Register
                  </Link>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <TnC showTandC={showTandC} handleCloseTandC={handleCloseTandC}/>
    </div>
    );
  };

  return (
    genLoginForm()
  );
}