import { TableFooter, TablePagination, TableRow } from "@mui/material";

import TablePaginationActions from "./TablePaginationActions";

export default function CustomTableFooter({
  colspan,
  data,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}) {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[8, 16, 32, { label: "All", value: -1 }]}
          labelRowsPerPage={''}
          colSpan={colspan}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </TableFooter>
  );
}
