import { generatorApiInstance } from "./GPTApiInstance2";


const genIdea = (params) => generatorApiInstance.post("/genIdea", params);
const genSections = (params) =>
  generatorApiInstance.post("/genSections", params);
const genSectionPoints = (params) =>
  generatorApiInstance.post("/genSectionPoints", params);
const genSectionBody = (params) =>
  generatorApiInstance.post("/genSectionBody", params);

const expose = {
  genIdea,
  genSections,
  genSectionPoints,
  genSectionBody,
};
export default expose;
