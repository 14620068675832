import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { textStatistics } from "../../../util/textutil";

export default function StatsPlotWrapper({
  text,
  errorCallback,
  busyCallback,
}) {
  const [textStats, setTextStats] = useState({
    totalWords: 0,
    totalCharacters: 0,
    words: [],
    characters: [],
  });
  useEffect(() => {
    if (text.length > 1) {
      let result = textStatistics(text);
      setTextStats({
        totalWords: result.totalWords,
        totalCharacters: result.totalCharacters,
        words: [...result.words],
        characters: [...result.characters],
      });
    }
  }, [text]);

  return (
    <>
      Word count: {textStats.totalWords}
      <br />
      Character count: {textStats.totalCharacters}
      <br />
      Sentence length distribution
      <Plot
        data={[
          {
            x: textStats.words,
            type: "histogram",
          },
        ]}
        config={{
          displayModeBar: false,
        }}
        layout={{ width: 320, height: 240, title: "Words" }}
      />
      <Plot
        data={[
          {
            x: textStats.characters,
            type: "histogram",
          },
        ]}
        config={{
          displayModeBar: false,
        }}
        layout={{ width: 320, height: 240, title: "Characters" }}
      />
    </>
  );
}
