import configData from "../config.json";
import TokenStorage from "./TokenStorage";

const axios = require("axios");
export const generatorApiInstance = axios.create({
  baseURL: configData.baseGPTURL + "/chat/v2",
  timeout: 2 * 60 * 1000,
});


const tokenStorageService = TokenStorage.getService();

//USAGE:
// const result = await axiosApiInstance.post(url, data)

let AUTH_URL = configData.loginURL;
let TOKEN_ENDPOINT = "/profile/v1/Asperios/token";

async function refreshAccessToken() {
  console.log("starting refresh token");
  let refreshToken = tokenStorageService.getRefreshToken();
  console.log(refreshToken);
  if (!refreshToken) {
    return;
  }
  //compare token expiry
  if (
    isRefreshTokenExpired(refreshToken, tokenStorageService.getTokenTimestamp())
  ) {
    alert("Session expired");

    tokenStorageService.clearToken();
    window.logoutCallback();
  }

  try {
    await axios
      .post(AUTH_URL + TOKEN_ENDPOINT, {
        refreshToken: refreshToken,
      })
      .then(function (response) {
        console.log(response);
        //TODO error checking
        if (response.status === 200) {
          tokenStorageService.setToken(response.data);
        } else {
          //TODO - notify main window to log out (?)
          console.log(response.status);
          alert("Refresh token error\n(CODE 113)");
        }
      });
  } catch (e) {
    console.log(e);
    //alert("Refresh token error\n(CODE 114)");
  }
}

// Request interceptor for API calls
generatorApiInstance.interceptors.request.use(
  async (config) => {
    let accessToken = tokenStorageService.getAccessToken();
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
generatorApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    console.log("======================");
    console.log(originalRequest);
    console.log(error);
    // handle network errors
    if (typeof error.response === "undefined") return Promise.reject(error);
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return generatorApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

const isRefreshTokenExpired = (refreshToken, tokenTimestamp) => {
  const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));
  const expiry = tokenParts.exp;
  const issued = tokenParts.iat;
  const howOld = expiry - issued;
  const elapsed = Date.now() - tokenTimestamp;
  return elapsed > 1000 * howOld;
};
