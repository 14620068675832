Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _SubmenuModule = _interopRequireDefault(require("./Submenu.module.css"));

var _reactStyleClasses = _interopRequireDefault(require("react-style-classes"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Submenu = function Submenu(_ref) {
  var children = _ref.children,
      position = _ref.position,
      className = _ref.className;
  return _react.default.createElement("div", {
    className: (0, _reactStyleClasses.default)(_SubmenuModule.default.submenu, position === 'right' && _SubmenuModule.default.submenuRight, className)
  }, _react.default.createElement("ul", null, children));
};

Submenu.propTypes = {
  children: _propTypes.default.node,
  position: _propTypes.default.oneOf(['left', 'right']),
  className: _propTypes.default.string
};
Submenu.defaultProps = {
  children: null,
  position: 'left',
  className: null
};
var _default = Submenu;
exports.default = _default;