Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ItemModule = _interopRequireDefault(require("./Item.module.css"));

var _reactStyleClasses = _interopRequireDefault(require("react-style-classes"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Item = function Item(props) {
  return _react.default.createElement("li", {
    className: (0, _reactStyleClasses.default)(_ItemModule.default.item, props.isActive && _ItemModule.default.active, props.className),
    onClick: props.onClick,
    tabIndex: 0
  }, props.children);
};

Item.propTypes = {
  children: _propTypes.default.node,
  onClick: _propTypes.default.func,
  isActive: _propTypes.default.bool,
  className: _propTypes.default.string
};
Item.defaultProps = {
  children: null,
  onClick: function onClick() {
    return null;
  },
  isActive: false,
  className: null
};
var _default = Item;
exports.default = _default;