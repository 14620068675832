import React, { useContext } from "react";
import { styled } from '@mui/material/styles';

import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar,
} from "@mui/material";

import { AccountCircle } from "@mui/icons-material";

import MenuBar from "./menubar/MenuBar";
import configData from "../config.json";
import { UserContext } from "../Context";
import TokenStorage from "../api/TokenStorage";
const tokenStorageService = TokenStorage.getService();

const SectionRightToolbar = styled("section")({
  marginLeft: "auto",
  marginRight: -12,
});

const ToolbarCustomizeToolbar = styled(Toolbar)({
  minHeight: 36,
  backgroundColor: configData.Constants.BG_TITLE_BLUE,
});

const IconButtonIcon = styled(IconButton)({
  "& svg": {
    fontSize: 25,
    marginLeft: 8,
  },
});

const AccountCircleIcon = styled(AccountCircle)({
  "& svg": {
    fontSize: 25,
    marginLeft: 8,
  },
});

export default function AppTitleBar(props) {
  const [userContext, setUserContext] = useContext(UserContext);

  const [openLogout, setOpenLogout] = React.useState(false);

  const onAccountClick = () => {
    setOpenLogout(true);
  };
  const handleCancelLogout = () => {
    setOpenLogout(false);
  };
  const handleLogout = () => {
    setOpenLogout(false);
    tokenStorageService.clearToken();
    props.logoutCallback();
  };

  const genLogoutDialog = () => {
    return (
      <Dialog
        open={openLogout}
        onClose={handleCancelLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Logout?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLogout} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      <AppBar
        position="static"
        style={{ zIndex: 1000, position: "fixed", top: 0, left: 0, margin: 0 }}
      >
        <ToolbarCustomizeToolbar>
          <div>
            <MenuBar menuCallback={props.menuCallback} />
          </div>
          <SectionRightToolbar>
            {userContext?.selectedClientName ? <span>{userContext?.selectedClientName}</span> :  null}
            <IconButtonIcon
              color="inherit"
              aria-label="Account"
              onClick={() => {
                onAccountClick();
              }}
            >
              <AccountCircleIcon />
            </IconButtonIcon>
          </SectionRightToolbar>
        </ToolbarCustomizeToolbar>
      </AppBar>
      {genLogoutDialog()}
    </div>
  );
}
