import { useCallback, useEffect, useRef, useState } from "react";

import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";

import EditorJS from "@editorjs/editorjs";
import edjsHTML from "editorjs-html";

import getEditorJDTools from "./TextEditorPlugins";

import { processPlain } from "../../../util/textutil";
import { Stack, TextField } from "@mui/material";

import "../../../styles.css";
import HelpTooltip from "../../widgets/HelpTooltip";
//https://codesandbox.io/s/dj0fr?file=/index.js:1274-1307

const pageHeader = `<!DOCTYPE HTML>
<html>
<head>
	<meta charset="UTF-8">

	<meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />

	<title>##PAGETITLE##</title>
	<meta name="description" content="##PAGEDESCRIPTION##" />
	<meta property="og:type" content="##PAGETYPE##" />
	<meta property="og:title" content="##PAGETITLE##" />
	<meta property="og:description" content="##PAGEDESCRIPTION##" />
</head>
<body>
`;
const pageBottom = "</body></html>";

// var webPageTitle = '';
// var webPageDescription = '';

export default function TextEditor({
  id,
  context,
  text,
  seo,
  docType,
  model,
  callback,
  errorCallback,
  busyCallback,
  setBlocks,
  setRawText,
  setHtmlText,
  setTitle,
  setDescription,
  textRequest,
  setTextRequest,
  setDataAvailable,
}) {
  const editorInstance = useRef();

  const titleRef = useRef();
  const descriptionRef = useRef();

  const [webPageTitle, _setWebPageTitle] = useState('')
  const [webPageDescription, _setWebPageDescription] = useState('')

  const setWebPageTitle = (t) => {
    setTitle(t)
     _setWebPageTitle(t)
   }
   const getWebPageTitle = () => {
     return webPageTitle
   }

   const setWebPageDescription = (t) => {
      setDescription(t)
     _setWebPageDescription(t)
   }
   const getWebPageDescription = () => {
   return webPageDescription
 }

 function initEditor(id, context, text, model, errorCallback, busyCallback) {
    let blocks;
    if (typeof text === "string") blocks = processPlain(text);
    else blocks = text;
    let tools = getEditorJDTools(model, context, errorCallback, busyCallback);
    const editor = new EditorJS({
      holder: id,
      data: {
        blocks: blocks,
      },
      tools: tools.config,
      tunes: tools.tunes,
      defaultBlock: tools.defaultBlock,
      onReady: (api) => {
        editorInstance.current = editor;
      },
      onChange: (api, event) => {
        handleChange();
      },
    });
  }

  useEffect(() => {
    if (textRequest === true) {
      getText();
    }
  }, [textRequest]);

  useEffect(() => {
    if (!editorInstance.current) {
      initEditor(id, context, text, model, errorCallback, busyCallback);
    }
    return () => {
      if (editorInstance && editorInstance.current) {
        editorInstance.current.destroy();
        editorInstance.current = null;
      }
    };
  }, [text]);

  const extractTextFromBlocks = (blocks) => {
    let text = blocks.blocks
      .filter(
        (entry) =>
          entry.type === "header" ||
          entry.type === "paragraph" ||
          entry.type === "text"
      )
      .map((entry) => entry.data.text);
    return text;
  };

  const handleChange = useCallback(async () => {
    console.log("handlechange1");
    if (callback && editorInstance.current) {
      console.log("handlechange2");
      const savedData = await editorInstance.current.save();
      let text = extractTextFromBlocks(savedData);
      callback(text.join("\n"), savedData);
    }
  }, []);

  const blockToText = (blocks) => {
    let text = blocks.blocks
      .map((item) =>
        item.type === "header" || item.type === "paragraph"
          ? item.data.text
          : item.type === "list"
          ? item.data.items.join(" ")
          : " "
      )
      .join(" ");
    setRawText(text);
    setBlocks(blocks.blocks);
  };

  const generateHtml = (savedData) => {
    let htmlData = edjsParser.parse(savedData);
    let html = htmlData.join("\n");
    if (seo && webPageTitle.length > 0 && webPageDescription.length > 0) {
      let header = pageHeader.replaceAll("##PAGETITLE##", webPageTitle);
      header = header.replaceAll("##PAGEDESCRIPTION##", webPageDescription);
      if (docType && ["blog", "website", "article"].includes(docType))
        header = header.replaceAll("##PAGETYPE##", docType);
      else header = header.replaceAll("##PAGETYPE##", "article");
      html = header + html + pageBottom;
      //https://exposureninja.com/training/guides/seo/what-are-page-titles-descriptions/
    }
    return html;
  };
  const getText = useCallback(async () => {
    if (editorInstance.current) {
      const savedData = await editorInstance.current.save();
      let html = generateHtml(savedData);
      blockToText(savedData);
      setBlocks(savedData);
      setHtmlText(html);

      setTitle(titleRef?.current?.value)
      setDescription(descriptionRef?.current?.value)
      setDataAvailable(true);
      setTextRequest(false);
    } else {
      //TODO
      //errorCallback();
      console.log("no editor object");
    }
  }, []);

  const customLinkToolParser = (block) => {
    let url = block.data.link;
    let embedUrl = "https://www.youtube.com/embed/";

    const regex =
      /(youtu(?:\.be|be\.com)\/(?:.*v(?:\/|=)|(?:.*\/)?)([\w'-]+))/i;

    let m;
    if ((m = regex.exec(url)) !== null) {
      // The result can be accessed through the `m`-variable.
      console.log(m[2]);
      embedUrl = embedUrl + m[2];
      // m.forEach((match, groupIndex) => {
      //         console.log(`Found match, group ${groupIndex}: ${match}`);
      //     });
    } else embedUrl = "";

    return (
      '<iframe width="740" height="416" src="' +
      embedUrl +
      '" title="' +
      block.data.meta.title +
      '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
    );
  };
  const edjsParser = edjsHTML({ linkTool: customLinkToolParser });

  const generateWebSpecifics = () => {
    return (
      <div className="center90">
        <Stack spacing={2} direction="row">
          <TextField
            id="web-page-title"
            inputRef={titleRef}
            label="Web Page title"
            fullWidth
            rows={2}
            value={webPageTitle}
            onChange={(event) => setWebPageTitle(event.target.value)}
            variant="standard"
            size="small"
          />
          <HelpTooltip
            text={
              <div>
                <b>Web page title</b>
                <br />
                30-60 characters for mobile
                <br />
                30-70 characters for desktop
                <br />
              </div>
            }
          />
        </Stack>
        <Stack spacing={2} direction="row">
          <TextField
            id="web-page-description"
            inputRef={descriptionRef}
            label="Web Page Description"
            fullWidth
            value={webPageDescription}
            onChange={(event) => setWebPageDescription(event.target.value)}
            variant="standard"
            size="small"
          />
          <HelpTooltip
            text={
              <div>
                <b>Web page meta description</b>
                <br />
                70-130 characters for mobile
                <br />
                70-155 characters for desktop
              </div>
            }
          />
        </Stack>
      </div>
    );
  };

  return (
    <>
      {seo ? generateWebSpecifics() : null}
      <GrammarlyEditorPlugin
        clientId="client_62UA1pw55bw5bxfitd2HoL"
        id={id}
        style={{ width: "100%" }}
      />
    </>
  );
}
