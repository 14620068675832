import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import Accordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RefreshIcon from "@mui/icons-material/Refresh";

import EmotionPlotWrapper from "./EmotionPlotWrapper";
import SEOWrapper from "./SEOWrapper";
import StatsPlotWrapper from "./StatsPlotWrapper";
import WordcloudWrapper from "./WordcloudWrapper";
import { Box, Button, IconButton } from "@mui/material";

const test = false;

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export default function TextEditorToolbar({
  errorCallback,
  busyCallback,
  blocks,
  rawText,
  htmlText,
  textRequest,
  setTextRequest,
  title,
  description,
  dataAvailable,
  setDataAvailable,
  seoTargetKeyword,
}) {
  const [text, setText] = useState("");
  const [html, setHtml] = useState("");

  const [titleParam, setTitleParam] = useState(title);
  const [descriptionParam, setDescriptionParam] = useState(description);

  const [saveHtmlRequested, _setSaveHtmlRequested] = useState(false);
  const [emotionExpanded, setEmotionExpanded] = useState(false);
  const [seoExpanded, setSeoExpanded] = useState(false);
  const [statsExpanded, setStatsExpanded] = useState(false);
  const [wordcloudExpanded, setWordcloudExpanded] = useState(false);

  useEffect(() => {
    if (dataAvailable === true && rawText) {
      setText(rawText);
      setHtml(htmlText);
      setDataAvailable(false);
      if (saveHtmlRequested) {
        _setSaveHtmlRequested(false);
        handleSaveHtml();
      }
    }
  }, [dataAvailable, rawText]);
  useEffect(() => {
    console.log('set params', title, description)
    setTitleParam(title)
    setDescriptionParam(description)
  }, [title, description]);

  const requestData = () => {
    setTextRequest(true);
  };
  const setSaveHtmlRequested = (val) => {
    _setSaveHtmlRequested(val);
    requestData();
  };

  const handleSaveHtml = () => {
    if (htmlText.length > 0) {
      let filename = "text.html";

      var blob = new Blob([html], { type: "text/obj" }),
        e = document.createEvent("MouseEvents"),
        a = document.createElement("a");

      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ["text/obj", a.download, a.href].join(":");
      e.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      a.dispatchEvent(e);
    }
  };

  const generateTestTools = () => {
    return (
      <>
        <div>text: {textRequest ? "text req true" : "text req false"}</div>
        <div>
          data: {dataAvailable ? "data avail true" : "data avail false"}
        </div>
        <button onClick={() => setTextRequest(true)}>request</button>
        <button onClick={() => setDataAvailable(false)}>use data</button>
      </>
    );
  };

  const handleRefresh = () => {
    requestData();
  };

  return (
    <>
      <Button variant="contained" onClick={() => setSaveHtmlRequested(true)}>
        Save to HTML
      </Button>
      <Accordion
        style={{ width: "352px" }}
        TransitionProps={{ unmountOnExit: true }}
        onChange={(e, expanded) => {
          setEmotionExpanded(expanded);
          if (expanded) {
            requestData();
          }
        }}
      >
        <Box sx={{ display: "flex" }}>
          <AccordionSummary
            aria-controls="emotion-panel"
            id="emotion-panel-header"
            sx={{ flexGrow: 1 }}
          >
            <Typography>Emotion</Typography>
          </AccordionSummary>
          {emotionExpanded ? (
            <Box>
              <IconButton onClick={() => handleRefresh()}>
                <RefreshIcon />
              </IconButton>
            </Box>
          ) : null}
        </Box>
        <AccordionDetails>
          {text.length > 0 ? (
            <EmotionPlotWrapper
              text={text}
              showWords={true}
              errorCallback={errorCallback}
              busyCallback={busyCallback}
            />
          ) : null}
        </AccordionDetails>
      </Accordion>
      {seoTargetKeyword ? (
        <Accordion
          style={{ width: "352px" }}
          TransitionProps={{ unmountOnExit: true }}
          onChange={(e, expanded) => {
            setSeoExpanded(expanded);
            if (expanded) {
              requestData();
            }
          }}
        >
          <Box sx={{ display: "flex" }}>
            <AccordionSummary
              aria-controls="seo-panel"
              id="seo-panel-header"
              sx={{ flexGrow: 1 }}
            >
              <Typography>SEO</Typography>
            </AccordionSummary>
            {seoExpanded ? (
              <Box>
                <IconButton onClick={() => handleRefresh()}>
                  <RefreshIcon />
                </IconButton>
              </Box>
            ) : null}
          </Box>
          <AccordionDetails>
            {html.length > 0 ? (
              <SEOWrapper
                seoTargetKeyword={seoTargetKeyword}
                blocks={blocks}
                text={text}
                html={html}
                title={title}
                description={description}
                errorCallback={errorCallback}
                busyCallback={busyCallback}
              />
            ) : null}
          </AccordionDetails>
        </Accordion>
      ) : null}
      <Accordion
        style={{ width: "352px" }}
        TransitionProps={{ unmountOnExit: true }}
        onChange={(e, expanded) => {
          setStatsExpanded(expanded);
          if (expanded) {
            requestData();
          }
        }}
      >
        <Box sx={{ display: "flex" }}>
          <AccordionSummary
            aria-controls="stats-panel"
            id="stats-panel-header"
            sx={{ flexGrow: 1 }}
          >
            <Typography>Statistics</Typography>
          </AccordionSummary>
          {statsExpanded ? (
            <Box>
              <IconButton onClick={() => handleRefresh()}>
                <RefreshIcon />
              </IconButton>
            </Box>
          ) : null}
        </Box>
        <AccordionDetails>
          <Typography>
            {text.length > 0 ? (
              <StatsPlotWrapper
                text={text}
                errorCallback={errorCallback}
                busyCallback={busyCallback}
              />
            ) : null}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{ width: "352px" }}
        TransitionProps={{ unmountOnExit: true }}
        onChange={(e, expanded) => {
          setWordcloudExpanded(expanded);
          if (expanded) {
            requestData();
          }
        }}
      >
        <Box sx={{ display: "flex" }}>
          <AccordionSummary
            aria-controls="wc-panel"
            id="wc-panel-header"
            sx={{ flexGrow: 1 }}
          >
            <Typography>Word Cloud</Typography>
          </AccordionSummary>
          {wordcloudExpanded ? (
            <Box>
              <IconButton onClick={() => handleRefresh()}>
                <RefreshIcon />
              </IconButton>
            </Box>
          ) : null}
        </Box>
        <AccordionDetails>
          <Typography>
            {text.length > 0 ? (
              <WordcloudWrapper
                text={text}
                errorCallback={errorCallback}
                busyCallback={busyCallback}
              />
            ) : null}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {test ? generateTestTools() : null}
    </>
  );
}
