import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Grid,
  AppBar,
  Typography,
  Toolbar,
} from "@mui/material";

import { v4 as uuidv4 } from "uuid";

import "../../styles.css";
import configData from "../../config.json";

import LoginForm from "./LoginForm";
import PwdChangeForm from "./PwdChangeForm";
import ForgotPwdForm from "./ForgotPwdForm";

import AlertDialog from "../widgets/AlertDialog";
import Spinner from "../widgets/Spinner";

import axios from "axios";
import TokenStorage from "../../api/TokenStorage";
const tokenStorageService = TokenStorage.getService();

let LOGIN_URL = configData.loginURL;
let LOGIN_ENDPOINT = "/profile/v1/Asperios/login";
let CHGPWD_ENDPOINT = "/profile/v1/Asperios/changePwd";
let FORGOTPWD_ENDPOINT = "/profile/v1/Asperios/forgotPwd";

export default function Login(props) {
  const history = useHistory();

  const [visible, setVisible] = useState("LOGIN");
  const [busy, setBusy] = useState(false);

  const [dialogText, setDialogText] = useState({ title: "", text: "" });
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = () => {
    setDialogOpen(false);
  };
  
  const showForgotPwd = (showForgot) => {
    if(showForgot)
      setVisible("FORGOT")
    else
      setVisible("LOGIN")
  }

  const showRegister = () => {
    history.push("/selfRegistration");
  }

  useEffect(() => {
    let initial = tokenStorageService.getTokenTimestamp();

    if (initial != null) {
      var diff = Date.now() - initial;
      if (diff > 43200000) { //12 hours
        tokenStorageService.clearToken();
      }
    }
  }, []);

  const doLogin = async (username, password) => {
    setBusy(true)
    tokenStorageService.setSBUser('');
    try {
      const response = await axios
        .post(LOGIN_URL + LOGIN_ENDPOINT, {
          email: username,
          password: password,
        })
        .then(function (response) {
          setBusy(false)
          console.log(response);
          //TODO - no this here
          //this.setState({users: response.data, isFetching: false});
          //TODO error checking
          if (response.status === 200) {
            const session_id = uuidv4();
            // ReactGA.event({
            //   category: "login success",
            //   action: username.substring(0, username.indexOf("@")),
            // });

            let userInfo = {
              login: {
                access_token: response.data.access_token,
                refresh_token: response.data.refresh_token,
                session_id: session_id,
              },
            };

            tokenStorageService.setToken(response.data);
            tokenStorageService.setSBUser(username);
            tokenStorageService.setSessionId(session_id);

            if (props.loginCallback) {
                props.loginCallback({
                  login: true,
                });
              }
          } else {
            // ReactGA.event({
            //   category: "login failure",
            //   action: username.substring(0, username.indexOf("@")),
            // });
            setDialogText({
              title: "Login Error",
              text: "Incorrect Credentials! (CODE 111)"
            });
            setDialogOpen(true)  
          }
        });
    } catch (e) {
      // ReactGA.event({
      //   category: "login failure",
      //   action: username.substring(0, username.indexOf("@")),
      // });
      setBusy(false)
      console.log(e);
      if (e.response.status === 403) {
        let status = e.response?.data?.status
        if(status === 300){
          setDialogText({
            title: "Email Verification Required",
            text: "We have sent an email with a confirmation link to your email address. Please allow 5-10 minutes for this message to arrive."
          });
          setDialogOpen(true)  
        } else 
          setVisible("CHANGE");
      } else {
        setDialogText({
          title: "Login Error",
          text: "Unable to log you in, please try again later! (CODE 112)"
        });
        setDialogOpen(true)  
      }
    }
  };

  const doChangePwd = async (username, oldPassword, newPassword) => {
    setBusy(true)
    try {
      const response = await axios
        .post(LOGIN_URL + CHGPWD_ENDPOINT, {
          email: username,
          password: oldPassword,
          newPassword: newPassword,
        })
        .then(function (response) {
          setBusy(false)
          console.log(response);
          if (response.status === 200) {
            // ReactGA.event({
            //   category: "change pwd success",
            //   action: username.substring(0, username.indexOf("@")),
            // });
            setVisible("LOGIN");
          } else {
            // ReactGA.event({
            //   category: "change pwd failure",
            //   action: username.substring(0, username.indexOf("@")),
            // });
            setDialogText({
              title: "Error",
              text: "Error changing password! (CODE 113)"
            });
            setDialogOpen(true)  
          }
        });
    } catch (e) {
      // ReactGA.event({
      //   category: "change pwd failure",
      //   action: username.substring(0, username.indexOf("@")),
      // });
      setBusy(false)
      console.log(e);
      setDialogText({
        title: "Unable to change password",
        text: "Please try again later! (CODE 114)"
      });
      setDialogOpen(true)  
    }
  };

  const doForgotPwd = async (username) => {
    setBusy(true)
    try {
      const response = await axios
        .post(LOGIN_URL + FORGOTPWD_ENDPOINT, {
          email: username,
          password: ""
        })
        .then(function (response) {
          setBusy(false)
          console.log(response);
          if (response.status === 200) {
            // ReactGA.event({
            //   category: "forgot pwd success",
            //   action: username.substring(0, username.indexOf("@")),
            // });
            setVisible("LOGIN");
          } else {
            // ReactGA.event({
            //   category: "forgot pwd failure",
            //   action: username.substring(0, username.indexOf("@")),
            // });
            setDialogText({
              title: "Error",
              text: "Please try again later! (CODE 115)"
            });
            setDialogOpen(true)  
          }
        });
    } catch (e) {
      // ReactGA.event({
      //   category: "forgot pwd failure",
      //   action: username.substring(0, username.indexOf("@")),
      // });
      setBusy(false)
      console.log(e);
      setDialogText({
        title: "Error",
        text: "Please try again later! (CODE 116)"
      });
      setDialogOpen(true)  
    }
  };

  return (
    <div>
      <AppBar position="static" alignitems="center" color="primary">
        <Toolbar>
          <Grid container justifyContent="center" wrap="wrap">
            <Grid item>
              <Typography variant="h6" color="white">
                Asperios B2B by Semantic Brain
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {visible === "LOGIN" ? <LoginForm LoginFunc={doLogin} ShowForgotPwdFunc={showForgotPwd} ShowRegisterFunc={showRegister} /> : null}
      {visible === "CHANGE" ? <PwdChangeForm ChangePwdFunc={doChangePwd} /> : null}
      {visible === "FORGOT" ? <ForgotPwdForm ForgotPwdFunc={doForgotPwd} ShowForgotPwdFunc={showForgotPwd} /> : null}
      <AlertDialog
        dialogOpen={dialogOpen}
        title={dialogText.title}
        text={dialogText.text}
        callback={closeDialog}
      />
      <Spinner busy={busy} />
    </div>
  );
}
