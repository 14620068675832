import React, { useEffect, useState, lazy, Suspense } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useGA4React } from "ga-4-react";

import AppTitleBar from "./components/AppTitleBar";
import Login from "./components/login/Login";

import ErrorBoundary from "./components/widgets/ErrorBoundary";

import { UserContext } from "./Context";

import TokenStorage from "./api/TokenStorage";
import clientApi from "./api/clients";
import useApi from "./hooks/useApi";
import configData from "./config.json";

import TextEditorWrapper from "./components/text/TextComponents/TextEditorWrapper";
import { blocks } from "./TestTextBlocks";
import MultiStepTextGenerator from "./components/text/MultiStepTextGenerator";

const Registration = lazy(() =>
  import("./components/registration/Registration")
);
const AgencyClients = lazy(() => import("./components/agency/AgencyClients"));
const AnalyticsHelp = lazy(() => import("./components/help/AnalyticsHelp"));
const NotImplemented = lazy(() => import("./components/help/NotImplemented"));
const PrivacyHelp = lazy(() => import("./components/help/PrivacyHelp"));
const SetupHelp = lazy(() => import("./components/help/SetupHelp"));
const ShowSID = lazy(() => import("./components/help/ShowSID"));
const ImplementationNotes = lazy(() =>
  import("./components/help/ImplementationNotes")
);
const CustomerCommunication = lazy(() =>
  import("./components/campaigns/CustomerCommunication")
);
const CustomerCommunicationOrig = lazy(() =>
  import("./components/campaigns/CustomerCommunicationOrig")
);
const ViewCampaigns = lazy(() =>
  import("./components/campaigns/ViewCampaigns")
);
const ViewCustomerCommunication = lazy(() =>
  import("./components/campaigns/ViewCustomerCommunication")
);
const AccessAnalytics = lazy(() =>
  import("./components/analytics/AccessAnalytics")
);
const CampaignAnalytics = lazy(() =>
  import("./components/analytics/CampaignAnalytics")
);
const CommunicationsByAccount = lazy(() =>
  import("./components/analytics/CommunicationsByAccount")
);
const StatsByCommunications = lazy(() =>
  import("./components/analytics/StatsByCommunications")
);
const StatsByCommunicationsNoVisitID = lazy(() =>
  import("./components/analytics/StatsByCommunicationsNoVisitID")
);
const ManualContactEntry = lazy(() =>
  import("./components/campaigns/ManualContactEntry")
);
const NewCampaign = lazy(() => import("./components/campaigns/NewCampaign"));
const UploadContactsCSV = lazy(() =>
  import("./components/campaigns/UploadContactsCSV")
);
const NotificationsAnalytics = lazy(() =>
  import("./components/notifications/Analytics")
);

const CommunicationTemplates = lazy(() =>
  import("./components/templates/CommunicationTemplates")
);
const Report = lazy(() => import("./components/report/Report"));
const EmailGenerator = lazy(() => import("./components/text/EmailGenerator"));
const TextGenerator = lazy(() => import("./components/text/TextGenerator"));
const GeneratorBySample = lazy(() =>
  import("./components/text/GeneratorBySample")
);
const GeneratorByTopic = lazy(() =>
  import("./components/text/GeneratorByTopic")
);

const IFrames = lazy(() => import("./components/widgets/IFrames"));

const tokenStorageService = TokenStorage.getService();

export default function App() {
  const history = useHistory();
  const getSitesApi = useApi(clientApi.getSites);
  //TEMP - PATCH
  const patchAnalyticsApi = useApi(clientApi.patchAnalytics);

  useEffect(() => {
    document.title = "Asperios B2B";
  });
  const [sessionid, setSessionid] = useState(
    tokenStorageService.getSessionId()
  );
  const [page, setPage] = useState(null);
  const [userContext, setUserContext] = useState(null);

  const ga4 = useGA4React();
  let location = useLocation();

  React.useEffect(() => {
    if (!configData.GA_TRACKING_BLOCKED && !!ga4) {
      console.log(location);
      ga4.pageview(location.path, location.path, "page");
    }
  }, [location]);

  useEffect(() => {
    setPage({ target: "/campaignAnalytics" });
  }, []);
  function loginCallback(args) {
    console.log(args);
    if (args.login) {
      let client = tokenStorageService.getClient();
      if (client) {
        setSessionid(tokenStorageService.getSessionId());
        let agency = tokenStorageService.getAgency();
        if (agency) {
          console.log(agency);
          let tempUserContext = {
            manager: tokenStorageService.getManager(),
            agency: tokenStorageService.getAgency(),
            selectedClient: null,
            selectedClientName: null,
            selectedSID: null,
            selectedURL: null,
            selectedUserName: null,
            accessToken: tokenStorageService.getAccessToken(),
          };
          setUserContext(tempUserContext);
          tokenStorageService.setUserContext(tempUserContext);
        } else {
          getSitesApi.request(client);
        }
      }
    }
  }

  useEffect(() => {
    if (getSitesApi.data) {
      let client = Object.keys(getSitesApi.data.data)[0];
      let name = getSitesApi.data.data[client][0]["name"];
      let sid = getSitesApi.data.data[client][0]["sid"];
      let baseURL = getSitesApi.data.data[client][0]["baseURL"];

      let tempUserContext = {
        manager: tokenStorageService.getManager(),
        agency: null,
        selectedClient: client,
        selectedClientName: name,
        selectedSID: sid,
        selectedURL: baseURL,
        selectedUserName: tokenStorageService.getUserName(),
        accessToken: tokenStorageService.getAccessToken(),
      };
      setUserContext(tempUserContext);
      tokenStorageService.setUserContext(tempUserContext);
    }
  }, [getSitesApi.data]);
  useEffect(() => {
    if (getSitesApi.error && getSitesApi.error !== "") {
      console.log(getSitesApi.error);
      alert("Error getting sites");
    }
  }, [getSitesApi.error]);

  useEffect(() => {
    if (!userContext) {
      let tempUserContext = tokenStorageService.getUserContext();
      if (tempUserContext) setUserContext(tempUserContext);
    }
  }, [userContext]);

  function registrationCallback(args) {
    history.push("/");
    setSessionid(null);
  }

  const logoutCallback = () => {
    history.push("/");
    setSessionid(null);
  };
  window.logoutCallback = logoutCallback;

  function menuCallback(args) {
    console.log(args);

    //TEMP - PATCH
    if (args.pathname === "/patchAnalytics") patchAnalyticsApi.request();
    else if (args.pathname) history.push(args.pathname);
  }

  if (window.location.search.length > 0) {
    let query = window.location.search;
    if (query.startsWith("?rsvp=")) {
      let token = query.slice("?rsvp=".length);
      return (
        <ErrorBoundary>
          <Registration
            selfRegistration={false}
            token={token}
            registrationCallback={registrationCallback}
          />
        </ErrorBoundary>
      );
    }
  }

  function routerMenu() {
    return (
      <Switch>
        <Route path="/" exact>
          {userContext?.agency ? (
            <ErrorBoundary>
              <AgencyClients />
            </ErrorBoundary>
          ) : (
            <ErrorBoundary>
              {/* <CustomerCommunication /> */}
              <MultiStepTextGenerator/>
            </ErrorBoundary>
          )}
        </Route>
        <Route path="/selfRegistration" exact>
          <ErrorBoundary>
            <Registration
              selfRegistration={true}
              agencyRegistration={false}
              registrationCallback={registrationCallback}
            />
          </ErrorBoundary>
        </Route>
        <Route path="/agencyRegistration" exact>
          <ErrorBoundary>
            <Registration
              selfRegistration={true}
              agencyRegistration={true}
              registrationCallback={registrationCallback}
            />
          </ErrorBoundary>
        </Route>
        <Route path="/agencyClients" exact>
          <ErrorBoundary>
            <AgencyClients />
          </ErrorBoundary>
        </Route>

        <Route path="/notificationsAnalytics" exact>
          <ErrorBoundary>
            <NotificationsAnalytics />
          </ErrorBoundary>
        </Route>
        <Route path="/customerCommunication" exact>
          <ErrorBoundary>
            <CustomerCommunication />
          </ErrorBoundary>
        </Route>
        <Route path="/customerCommunicationOrig" exact>
          <ErrorBoundary>
            <CustomerCommunicationOrig />
          </ErrorBoundary>
        </Route>
        <Route path="/viewCustomerCommunication" exact>
          <ErrorBoundary>
            <ViewCustomerCommunication />
          </ErrorBoundary>
        </Route>
        <Route path="/viewSalesCampaigns" exact>
          <ErrorBoundary>
            <ViewCampaigns />
          </ErrorBoundary>
        </Route>
        <Route path="/uploadSalesContacts" exact>
          <ErrorBoundary>
            <UploadContactsCSV />
          </ErrorBoundary>
        </Route>
        <Route path="/createSalesCampaign" exact>
          <ErrorBoundary>
            <NewCampaign />
          </ErrorBoundary>
        </Route>
        <Route path="/createSalesContact" exact>
          <ErrorBoundary>
            <ManualContactEntry />
          </ErrorBoundary>
        </Route>
        <Route path="/accessAnalytics" exact>
          <ErrorBoundary>
            <AccessAnalytics />
          </ErrorBoundary>
        </Route>
        <Route path="/marketingAnalytics" exact>
          <ErrorBoundary>
            <CampaignAnalytics />
          </ErrorBoundary>
        </Route>
        <Route path="/salesAnalytics" exact>
          <ErrorBoundary>
            <CampaignAnalytics />
          </ErrorBoundary>
        </Route>


        <Route path="/editor" exact>
          <ErrorBoundary>
            <TextEditorWrapper id={"demo-editor"} seo={true} seoTargetKeyword={"ChatGPT in Marketing and Sales"} docType={"website"} model={"FULL"} text={blocks} />
          </ErrorBoundary>
        </Route>
        <Route path="/smalleditor" exact>
          <ErrorBoundary>
            <TextEditorWrapper
              id={"demo-editor"}
              seo={false}
              model={"HEADING"}
              text={[
                {
                  id: "sheNwCUP5A",
                  type: "header",
                  data: {
                    text: "Using ChatGPT to get more out of Marketing and Sales",
                    level: 2,
                  },
                },
              ]}
            />
          </ErrorBoundary>
        </Route>
        <Route path="/multi" exact>
          <ErrorBoundary>
            <MultiStepTextGenerator />
          </ErrorBoundary>
        </Route>

        <Route path="/emailGenerator" exact>
          <ErrorBoundary>
            <EmailGenerator />
          </ErrorBoundary>
        </Route>
        <Route path="/textGenerator" exact>
          <ErrorBoundary>
            <TextGenerator />
          </ErrorBoundary>
        </Route>
        <Route path="/genByTopic" exact>
          <ErrorBoundary>
            <GeneratorByTopic />
          </ErrorBoundary>
        </Route>
        <Route path="/genBySample" exact>
          <ErrorBoundary>
            <GeneratorBySample />
          </ErrorBoundary>
        </Route>

        <Route path="/communicationsByAccount" exact>
          <ErrorBoundary>
            <CommunicationsByAccount />
          </ErrorBoundary>
        </Route>
        <Route path="/oldStatsByCommunications" exact>
          <ErrorBoundary>
            <StatsByCommunicationsNoVisitID />
          </ErrorBoundary>
        </Route>
        <Route path="/statsByCommunications" exact>
          <ErrorBoundary>
            <StatsByCommunications />
          </ErrorBoundary>
        </Route>
        <Route path="/communicationTemplates" exact>
          <ErrorBoundary>
            <CommunicationTemplates />
          </ErrorBoundary>
        </Route>

        <Route path="/webSiteReport" exact>
          <ErrorBoundary>
            <Report />
          </ErrorBoundary>
        </Route>

        <Route path="/marketing" exact>
          <ErrorBoundary>
            <NotImplemented />
          </ErrorBoundary>
        </Route>
        <Route path="/marketingOptimization" exact>
          <ErrorBoundary>
            <IFrames
              url={configData.marketingOptimizationBaseURL + '?client=' + userContext.selectedClientName}
              params={''}
            />
          </ErrorBoundary>
        </Route>

        <Route path="/notificationsAnalytics" exact>
          <ErrorBoundary>
            <NotificationsAnalytics />
          </ErrorBoundary>
        </Route>

        <Route path="/analyticsHelp" exact>
          <ErrorBoundary>
            <AnalyticsHelp />
          </ErrorBoundary>
        </Route>
        <Route path="/privacyHelp" exact>
          <ErrorBoundary>
            <PrivacyHelp />
          </ErrorBoundary>
        </Route>
        <Route path="/setupHelp" exact>
          <ErrorBoundary>
            <SetupHelp />
          </ErrorBoundary>
        </Route>
        <Route path="/showSID" exact>
          <ErrorBoundary>
            <ShowSID />
          </ErrorBoundary>
        </Route>
        <Route path="/implementation" exact>
          <ErrorBoundary>
            <ImplementationNotes />
          </ErrorBoundary>
        </Route>
        <Route path="/userGuide" exact>
          <ErrorBoundary>
            <NotImplemented />
          </ErrorBoundary>
        </Route>
      </Switch>
    );
  }

  const genPage = () => {
    return sessionid && userContext ? (
      <div>
        <ThemeProvider theme={theme}>
          <UserContext.Provider value={[userContext, setUserContext]}>
            <AppTitleBar
              menuCallback={menuCallback}
              logoutCallback={logoutCallback}
            />
            <div className="content-container">{routerMenu()}</div>
          </UserContext.Provider>
        </ThemeProvider>
      </div>
    ) : (
      <Login loginCallback={loginCallback} />
    );
  };
  return (
    <div className="App">
      <Suspense fallback={<h1>Loading...</h1>}>
        {window.location.pathname === "/selfRegistration" ||
        window.location.pathname === "/agencyRegistration" ? (
          <ErrorBoundary>
            <ThemeProvider theme={theme}>
              <Registration
                agencyRegistration={
                  window.location.pathname === "/agencyRegistration"
                }
                selfRegistration={true}
                registrationCallback={registrationCallback}
              />
            </ThemeProvider>
          </ErrorBoundary>
        ) : (
          genPage()
        )}
      </Suspense>
    </div>
  );
}
