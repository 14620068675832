import jwt_decode from "jwt-decode";
import configData from "../config.json";

const TokenStorage = (function () {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setToken(tokenObj) {
    window.localStorage.setItem("access_token", tokenObj.access_token);
    window.localStorage.setItem("refresh_token", tokenObj.refresh_token);
    window.localStorage.setItem("token_timestamp", Date.now());
  }
  function _setSessionId(session_id) {
    window.localStorage.setItem("session_id", session_id);
  }
  function _setSBUser(user) {
    window.localStorage.setItem("sb_user", user);
  }
  function _setUserContext(userContext) {
    window.localStorage.setItem("user_context", JSON.stringify(userContext));
  }
  function _getUserContext() {
    let newObject = window.localStorage.getItem("user_context");
    if(newObject)
      return JSON.parse(newObject);
    else
      return null
  }
  function _getSBUser() {
    return window.localStorage.getItem("sb_user");
  }
  function _getAccessToken() {
    return window.localStorage.getItem("access_token");
  }
  function _getRefreshToken() {
    return window.localStorage.getItem("refresh_token");
  }
  function _getSessionId() {
    return window.localStorage.getItem("session_id");
  }
  function _getTokenTimestamp() {
    return window.localStorage.getItem("token_timestamp");
  }
  function _clearToken() {
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem("token_timestamp");
    window.localStorage.removeItem("session_id");
    window.localStorage.removeItem("user_context");
    window.localStorage.removeItem("agency");
  }

  function _getRoles() {
    let authClient = configData.Constants.AuthClient;
    let token = _getAccessToken();
    let roles = null;
    if (token) {
      let decoded = jwt_decode(token);
      if (
        decoded != null &&
        decoded.resource_access != null &&
        decoded.resource_access[authClient] != null &&
        decoded.resource_access[authClient].roles != null
      ) {
        roles = decoded.resource_access[authClient].roles;
      }
    }
    return roles;
  }

  function _getClient() {
    let authClient = configData.Constants.AuthClient;
    let token = _getAccessToken();
    let client = null;
    if (token) {
      let decoded = jwt_decode(token);
      if (decoded != null) {
        client = decoded.client;
      }
    }
    return client;
  }

  function _getField(field) {
    let token = _getAccessToken();
    let value = null;
    if (token) {
      let decoded = jwt_decode(token);
      if (decoded != null) {
        value = decoded[field];
      }
    }
    return value;
  }

  function _getManager() {
    return _getField('manager');
  }

  function _getAgency() {
    return _getField('agency');
  }

  function _getUserName() {
    return _getField('name');
  }

  return {
    getService: _getService,
    setToken: _setToken,
    setSessionId: _setSessionId,
    setSBUser: _setSBUser,
    getSBUser: _getSBUser,
    setUserContext: _setUserContext,
    getUserContext: _getUserContext,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    getSessionId: _getSessionId,
    getTokenTimestamp: _getTokenTimestamp,
    clearToken: _clearToken,
    getRoles: _getRoles,
    getClient: _getClient,
    getManager: _getManager,
    getAgency: _getAgency,
    getUserName: _getUserName,
  };
})();
export default TokenStorage;