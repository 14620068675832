import React, { useEffect, useState } from "react";

import { wordsFromText } from "../../../util/textutil";
import { TagCloud } from "react-tagcloud";

export default function WordcloudWrapper({
  text,
  errorCallback,
  busyCallback,
}) {
  const [data, setData] = useState([]);

  useEffect(() => {
    let w = wordsFromText(text)
    
    if(w.length > 50)
      w = w.slice(0, 50);
    setData(w);
  }, [text]);

  return (
    <div>
      <TagCloud
        minSize={12}
        maxSize={35}
        tags={data}
        onClick={(tag) => alert(`'${tag.value}' was selected!`)}
      />
    </div>
  );
}
