import { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";

import Item from "../widgets/Item";
import SectionEditor from "./SectionEditor";

import textApi from "../../api/gpt2";
import useApi from "../../hooks/useApi";
import { TextGeneratorContext } from "../../Context";

import { joinToQuotedText } from "../../util/textutil";
import { augmentErrorTryAgain } from "../../util/errorutil";

export default function TextGeneratorStep2({
  handleBack,
  handleNext,
  busyCallback,
}) {
  const [formContext, setFormContext] = useContext(TextGeneratorContext);
  const genSectionPointsApi = useApi(textApi.genSectionPoints);

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  const [sectionPoints, setSectionPoints] = useState(null);

  let workSections = null;
  let workSectionPoints = null;

  useEffect(() => {
    busyCallback(true);
    let req = {
      title: formContext?.title,
      type: formContext?.type,
      topic: formContext?.topic,
      tone: formContext?.tone,
      audience: formContext?.audience,
      description: formContext?.description,
      sections: formContext?.sections,
    };
    if (formContext?.keywords?.length > 0) {
      let keywordsText = joinToQuotedText(formContext?.keywords);
      req["keywords"] = keywordsText;
    }
    genSectionPointsApi.request(req);
  }, [formContext?.sections]);

  const processDocument = (sections, result) => {
    let sectionPointsArray = [];
    for (let section of sections) {
      let resultArray = result[section];
      sectionPointsArray.push([...resultArray]);
    }
    setSectionPoints(sectionPointsArray);
    workSections = sections;
    workSectionPoints = sectionPointsArray;

    setFormContext({
      ...formContext,
      sectionPoints: sectionPointsArray,
    });
    setNextButtonDisabled(false);
  };

  useEffect(() => {
    if (genSectionPointsApi.data) {
      busyCallback(false);
      let result = genSectionPointsApi.data.data;
      let resultObj = JSON.parse(result);
      processDocument(formContext.sections, resultObj);
    }
  }, [genSectionPointsApi.data]);
  useEffect(() => {
    if (genSectionPointsApi.error && genSectionPointsApi.error !== "") {
      if (
        genSectionPointsApi.error?.response?.status === 400 &&
        genSectionPointsApi.error?.response?.data?.status?.statusCode === 6
      ) {
        busyCallback(
          false,
          "Your request has been flagged by ConversionAI moderation: " +
            genSectionPointsApi.error.response.data.data
        );
      } else {
        let errorText = augmentErrorTryAgain(
          "Error generating points",
          genSectionPointsApi.error
        );
        busyCallback(false, errorText);
      }
    }
  }, [genSectionPointsApi.error]);

  const tableSectionsChangeCallback = (sectionIndex, pointIndex, value) => {
    let tempSections = workSections;
    let tempSectionPoints = sectionPoints;
    if (pointIndex === null) {
      tempSections[sectionIndex] = value;
      workSections = [...tempSections];
    } else {
      tempSectionPoints[sectionIndex][pointIndex] = value;
      workSectionPoints = [...tempSectionPoints];
    }
  };

  const getWorkSections = () => {
    if (workSections) return workSections;
    else return formContext.sections;
  };
  const getWorkSectionPoints = () => {
    if (workSectionPoints) {
      return workSectionPoints;
    } else if (sectionPoints) {
      return sectionPoints;
    } else {
      return formContext.sectionPoints;
    }
  };

  const localHandleNext = (val) => {
    setFormContext({
      ...formContext,
      sections: getWorkSections(),
      sectionPoints: getWorkSectionPoints(),
    });

    handleNext(val);
  };

  const _addAtIndex = (sectionIndex, insertAt) => {
    if(insertAt === null) 
      insertAt = 0
    else
      insertAt = insertAt + 1
    let tempSectionPoints = getWorkSectionPoints();
    let sectionToUpdate = tempSectionPoints[sectionIndex];
    let updatedSection = [
      ...sectionToUpdate.slice(0, insertAt),
      "",
      ...sectionToUpdate.slice(insertAt),
    ];
    let tempResult = [
      ...tempSectionPoints.slice(0, sectionIndex),
      [...updatedSection],
      ...tempSectionPoints.slice(sectionIndex + 1),
    ];
    workSectionPoints = null;
    setSectionPoints(tempResult);
  };
  const _deleteAtIndex = (sectionIndex, deleteAt) => {
    let tempSectionPoints = getWorkSectionPoints();
    let sectionToUpdate = tempSectionPoints[sectionIndex];
    let updatedSection = [
      ...sectionToUpdate.slice(0, deleteAt - 1),
      ...sectionToUpdate.slice(deleteAt),
    ];
    let tempResult = [
      ...tempSectionPoints.slice(0, sectionIndex),
      [...updatedSection],
      ...tempSectionPoints.slice(sectionIndex + 1),
    ];

    workSectionPoints = null;
    setSectionPoints(tempResult);
  };
  const addRemoveCallback = (action, sectionIndex, pointIndex) => {
    switch (action) {
      case "ADD":
        _addAtIndex(sectionIndex, pointIndex);
        break;
      case "DELETE":
        _deleteAtIndex(sectionIndex, pointIndex + 1);
        break;
      default:
        break;
    }
  };
  const generateEditor = () => {
    return (
      <SectionEditor
        sections={formContext.sections}
        sectionPoints={sectionPoints}
        changeCallback={tableSectionsChangeCallback}
        addRemoveCallback={addRemoveCallback}
      />
    );
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid style={{ width: "75%" }} item xs={9}>
        <Item>{sectionPoints ? generateEditor() : null}</Item>
      </Grid>
      <Grid item xs={3}>
        <Item>
          <button onClick={() => handleBack(false)}>Prev</button>
          <button
            disabled={nextButtonDisabled}
            onClick={() => localHandleNext(true)}
          >
            Next
          </button>
        </Item>
      </Grid>
    </Grid>
  );
}
