import { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import GeneratorSetup from "./TextComponents/GeneratorSetup";

import textApiV1 from "../../api/gpt";
import textApi from "../../api/gpt2";
import useApi from "../../hooks/useApi";
import { TextGeneratorContext } from "../../Context";

import Item from "../widgets/Item";
import { StyledTableHead } from "../widgets/StyledWidgets";
import { augmentErrorTryAgain } from "../../util/errorutil";

const generatorTypes = [
  { name: "Blog", value: "blog" },
  { name: "Article", value: "article" },
  { name: "News Release", value: "news" },
  { name: "Web Page", value: "website" },
];
const generatorAudience = [
  { name: "CEO", value: "CEO" },
  { name: "CMO", value: "CMO" },
  { name: "CTO", value: "CTO" },
  { name: "IT specialist", value: "IT specialist" },
];
const generatorTone = [
  { name: "Formal", value: "formal" },
  { name: "Informal", value: "informal" },
  { name: "Professional", value: "professional" },
  { name: "Technical", value: "technical" },
];

export default function TextGeneratorStep0({
  handleBack,
  handleNext,
  busyCallback,
}) {
  const [formContext, setFormContext] = useContext(TextGeneratorContext);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  const genIdeasApi = useApi(textApi.genIdea);
  const serpApi = useApi(textApiV1.serp);

  const [generatedTitles, _setGeneratedTitles] = useState(
    formContext?.generatedTitles
  );
  const [selectedTitle, setSelectedTitle] = useState(
    formContext?.selectedTitle
  );

  const setGeneratedTitles = (value) => {
    _setGeneratedTitles([...value]);
    setFormContext({ ...formContext, generatedTitles: [...value] });
  };

  const localHandleNext = (val) => {
    if (
      formContext.seoOptimized === true &&
      formContext.seoTargetKeyword &&
      formContext.seoTargetKeyword.length > 1
    ){
      console.log('serp request')
      serpApi.request(formContext.seoTargetKeyword);
    }
    handleNext(val);
  };
  useEffect(() => {
    if (genIdeasApi.data) {
      busyCallback(false);
      let result = genIdeasApi.data.data;
      let resultObj = JSON.parse(result);
      let resultArray = resultObj["titles"];
      setGeneratedTitles(resultArray);
    }
  }, [genIdeasApi.data]);
  useEffect(() => {
    if (genIdeasApi.error && genIdeasApi.error !== "") {
      if (
        genIdeasApi.error?.response?.status === 400 &&
        genIdeasApi.error?.response?.data?.status?.statusCode === 6
      ) {
        busyCallback(
          false,
          "Your request has been flagged by ConversionAI moderation: " +
            genIdeasApi.error.response.data.data
        );
      } else {
        let errorText = augmentErrorTryAgain(
          "Error generating ideas",
          genIdeasApi.error
        );
        busyCallback(false, errorText);
      }
    }
  }, [genIdeasApi.error]);

  const generateCallback = (value) => {
    setFormContext({
      ...formContext,
      type: value.type,
      audience: value.audience,
      tone: value.tone,
      titleKeyword: value.titleKeyword,
      topic: value.topic,
      description: value.description,
    });
    if (value.topic.length > 5) {
      busyCallback(true);
      let request = {
        type: value.type,
        topic: value.topic,
        tone: value.tone,
        audience: value.audience,
        description: value.description,
      };
      if (
        value.titleKeyword &&
        (typeof value.titleKeyword === "string" ||
          value.titleKeyword instanceof String) &&
        value.titleKeyword.length > 0
      )
        request["keyword"] = value.titleKeyword;
      genIdeasApi.request(request);
    }
  };
  const handleSelectRow = (event, value) => {
    setSelectedTitle(value);
    setFormContext({ ...formContext, title: value });
    setNextButtonDisabled(false);
  };

  const generateTable = () => {
    return (
      <TableContainer component={Paper} style={{ width: "100%" }}>
        <Table size="small" aria-label="simple table">
          <StyledTableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Selected</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {generatedTitles.map((entry, index) => {
              return (
                <TableRow
                  key={entry}
                  onClick={(event) => handleSelectRow(event, entry)}
                >
                  <TableCell component="th" scope="row">
                    {entry}
                  </TableCell>
                  <TableCell padding="checkbox" component="th" scope="row">
                    <Radio checked={entry === selectedTitle} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const generateTopicBox = () => {
    if (formContext)
      return (
        <Box
          component="form"
          sx={{ "& > :not(style)": { m: 1, width: "160ch" } }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={2}>
            <h2 style={{ marginBottom: "0px" }}>
              Generate titles based on a topic
            </h2>
            <Stack spacing={2} direction="row" style={{ marginTop: "0px" }}>
              <GeneratorSetup
                generateCallback={generateCallback}
                generatorTypes={generatorTypes}
                generatorAudience={generatorAudience}
                generatorTone={generatorTone}
              />
            </Stack>
            {generatedTitles ? generateTable() : null}
          </Stack>
        </Box>
      );
    else return null;
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={3}>
          <Item>{generateTopicBox()}</Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <button
              disabled={nextButtonDisabled}
              onClick={() => localHandleNext(true)}
            >
              Next
            </button>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}
