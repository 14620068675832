import { useContext, useEffect, useState } from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ButtonInnerDiv, ItemOuterDiv } from "../../widgets/StyledWidgets";

import AutocompleteControlled from "./AutocompleteControlled";
import BorderlessItem from "../../widgets/BorderlessItem";
import { TextGeneratorContext } from "../../../Context";

export default function GeneratorSetup({
  generateCallback,
  generatorTypes,
  generatorAudience,
  generatorTone,
}) {
  const [formContext, setFormContext] = useContext(TextGeneratorContext);

  const [generateEnabled, setGenerateEnabled] = useState(false);

  const [seoOptimized, setSeoOptimized] = useState(formContext?.seoOptimized);
  const [seoTargetKeyword, _setSeoTargetKeyword] = useState(formContext?.seoTargetKeyword);
  const [selectedType, setSelectedType] = useState(formContext?.type);
  const [selectedAudience, setSelectedAudience] = useState(
    formContext?.audience
  );
  const [selectedTone, setSelectedTone] = useState(formContext?.tone);
  const [selectedKeywords, setSelectedKeywords] = useState([
    formContext?.titleKeyword,
  ]);
  const [selectedTopic, setSelectedTopic] = useState(formContext?.topic);
  const [selectedDescription, setSelectedDescription] = useState(
    formContext?.description
  );

  const keywordsCallback = (value) => {
    setSelectedKeywords(value);
  };

  useEffect(() => {
    if (
      selectedKeywords &&
      selectedKeywords.length > 0 &&
      selectedTopic.length > 0
    )
      setGenerateEnabled(true);
    else setGenerateEnabled(false);
  }, [selectedKeywords, selectedTopic]);
  const handleChangeSeo = () => {
    let oldValue = formContext.seoOptimized;
    setFormContext({
      ...formContext,
      seoOptimized: !oldValue,
    });
    setSeoOptimized(!oldValue);
  };

  const setSeoTargetKeyword = (val) => {
    _setSeoTargetKeyword(val)
    setFormContext({
      ...formContext,
      seoTargetKeyword: val,
    });
  }

  const handleGenerate = () => {
    generateCallback({
      type: selectedType,
      audience: selectedAudience,
      tone: selectedTone,
      titleKeyword: selectedKeywords,
      topic: selectedTopic,
      description: selectedDescription,
    });
  };
  const clearForm = () => {
    setSeoOptimized(false);
    setSeoTargetKeyword(null);
    setSelectedType("blog");
    setSelectedAudience("CMO");
    setSelectedTone("formal");
    setSelectedKeywords([]);
    setSelectedTopic("");
    setSelectedDescription("");
    setFormContext({
      ...formContext,
      seoOptimized: false,
      seoTargetKeyword: null,
      type: "blog",
      audience: "CMO",
      tone: "formal",
      titleKeyword: "",
      topic: "",
      description: "",
    });
  };
  const generateConfigFirstRow = () => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={3}>
          <BorderlessItem>
            <FormControl size="small" sx={{ m: 1, minWidth: 180 }}>
              <InputLabel>Type</InputLabel>
              <Select
                labelId="gen-type-select-label"
                id="gen-type-select"
                value={selectedType}
                label="Type"
                onChange={(event) => setSelectedType(event.target.value)}
              >
                {generatorTypes.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </BorderlessItem>
        </Grid>
        <Grid item xs={3}>
          <BorderlessItem>
            <FormControl size="small" sx={{ m: 1, minWidth: 180 }}>
              <InputLabel>Audience</InputLabel>
              <Select
                labelId="gen-audience-select-label"
                id="gen-audience-select"
                value={selectedAudience}
                label="Audience"
                onChange={(event) => setSelectedAudience(event.target.value)}
              >
                {generatorAudience.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </BorderlessItem>
        </Grid>
        <Grid item xs={3}>
          <BorderlessItem>
            <FormControl size="small" sx={{ m: 1, minWidth: 180 }}>
              <InputLabel>Tone</InputLabel>
              <Select
                labelId="gen-tone-select-label"
                id="gen-tone-select"
                value={selectedTone}
                label="Tone"
                onChange={(event) => setSelectedTone(event.target.value)}
              >
                {generatorTone.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </BorderlessItem>
        </Grid>

        <Grid item xs={3}>
          <BorderlessItem style={{ marginTop: "10px" }}>
            {formContext?.siteKeywords &&
            formContext?.siteKeywords.length > 0 ? (
              <AutocompleteControlled
                label='Title Keyword'
                selection={selectedKeywords}
                keywords={formContext?.siteKeywords}
                callback={keywordsCallback}
                multiple={false}
              />
            ) : null}
          </BorderlessItem>
        </Grid>
      </Grid>
    );
  };
  const generateConfigSecondRow = () => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <BorderlessItem>
              <ItemOuterDiv>
                <TextField
                  id="topic-multiline-static"
                  label="Topic"
                  fullWidth
                  rows={2}
                  value={selectedTopic}
                  onChange={(event) => setSelectedTopic(event.target.value)}
                  variant="standard"
                  size="small"
                />
              </ItemOuterDiv>
            </BorderlessItem>
          </Grid>
          <Grid item xs={12}>
            {generateConfigButtons()}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <BorderlessItem>
            <ItemOuterDiv>
              <TextField
                id="description-multiline-static"
                label="Description"
                fullWidth
                multiline
                rows={6}
                value={selectedDescription}
                onChange={(event) => setSelectedDescription(event.target.value)}
                variant="standard"
                size="small"
              />
            </ItemOuterDiv>
          </BorderlessItem>
        </Grid>
      </Grid>
    );
  };

  const generateConfigButtons = () => {
    return (
      <>
        <FormGroup style={{ marginLeft: "26px" }}>
          <FormControlLabel
            control={
              <Checkbox checked={seoOptimized} onChange={handleChangeSeo} />
            }
            label="Web Publish"
          />
          {seoOptimized ?
              <TextField
              id="seo-keyword"
              label="SEO Target Keyword"
              fullWidth
              value={seoTargetKeyword}
              onChange={(event) => setSeoTargetKeyword(event.target.value)}
              variant="standard"
              size="small"
            />
        : null}
        </FormGroup>
        <BorderlessItem>
          <ButtonInnerDiv
            style={{ width: "200px", height: "28px", marginBottom: "6px" }}
            variant="contained"
            color="primary"
            disabled={!generateEnabled}
            onClick={handleGenerate}
          >
            Generate
          </ButtonInnerDiv>
          <ButtonInnerDiv
            style={{ width: "200px", height: "28px" }}
            variant="contained"
            color="primary"
            onClick={clearForm}
          >
            Clear
          </ButtonInnerDiv>
        </BorderlessItem>
      </>
    );
  };
  const generateConfig = () => {
    return (
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <BorderlessItem>{generateConfigFirstRow()}</BorderlessItem>
        </Grid>
        <Grid item xs={12}>
          <BorderlessItem>{generateConfigSecondRow()}</BorderlessItem>
        </Grid>
      </Grid>
    );
  };

  return <div style={{ textAlign: "left" }}>{generateConfig()}</div>;
}
