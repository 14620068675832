import { ntfcApiInstance } from "./NTFCApiInstance";
//TEMP - PATCH
import { ntfcApiInstance2 } from "./NTFCApiInstance";

const getSites = (clients) => ntfcApiInstance.post("/getSites", {clients: clients})

//TEMP - PATCH
const patchAnalytics = () =>  ntfcApiInstance2.get("/patchanalytics")

const expose = {
    getSites,
    patchAnalytics,
};
export default expose