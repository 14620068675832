import { useContext, useEffect, useState } from "react";
import { Box, Grid, Stack, TextField } from "@mui/material";

import Item from "../widgets/Item";

import textApi from "../../api/gpt2";
import useApi from "../../hooks/useApi";
import { TextGeneratorContext } from "../../Context";
import SectionEditor from "./SectionEditor";

import {joinToQuotedText} from "../../util/textutil";
import AutocompleteControlled from "./TextComponents/AutocompleteControlled";
import BorderlessItem from "../widgets/BorderlessItem";
import { ButtonInnerDiv, ItemOuterDiv } from "../widgets/StyledWidgets";
import { augmentErrorTryAgain } from "../../util/errorutil";

export default function TextGeneratorStep1({
  handleBack,
  handleNext,
  busyCallback,
}) {
  const [formContext, setFormContext] = useContext(TextGeneratorContext);

  const genSectionsApi = useApi(textApi.genSections);

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [workTitle, setWorkTitle] = useState(formContext.title);
  const [selectedKeywords, setSelectedKeywords] = useState(
    formContext?.keywords
  );
  const [sectionsResult, _setSectionsResult] = useState(formContext?.sections);
  let workSections = null;
  const handleBackWithCleanup = (flag) => {
    setSectionsResult(null);
    handleBack(flag);
  };

  useEffect(() => {
    if (sectionsResult) {
      setNextButtonDisabled(false);
    }
  }, [sectionsResult]);

  const setSectionsResult = (value) => {
    if (value) {
      _setSectionsResult([...value]);
      setFormContext({ ...formContext, sections: [...value] });
      setNextButtonDisabled(false);
    } else {
      _setSectionsResult(null);
      setFormContext({ ...formContext, sections: null });
      setNextButtonDisabled(true);
    }
  };
  const selectKeywords = (values) => {
    setSelectedKeywords([...values]);
    setFormContext({ ...formContext, keywords: values });
  };
  useEffect(() => {
    if (genSectionsApi.data) {
      busyCallback(false);
      let result = genSectionsApi.data.data;
      let resultObj = JSON.parse(result);
      let resultArray = [];
      for (let i = 1; i < 7; i++) {
        resultArray.push(resultObj["" + i]);
      }
      setSectionsResult(resultArray);
      workSections = [...resultArray];
    }
  }, [genSectionsApi.data]);
  useEffect(() => {
    if (genSectionsApi.error && genSectionsApi.error !== "") {
      if (
        genSectionsApi.error?.response?.status === 400 &&
        genSectionsApi.error?.response?.data?.status?.statusCode === 6
      ) {
        busyCallback(
          false,
          "Your request has been flagged by ConversionAI moderation: " +
            genSectionsApi.error.response.data.data
        );
      } else {
        let errorText = augmentErrorTryAgain(
          "Error generating ideas",
          genSectionsApi.error
        );
        busyCallback(false, errorText);
      }
    }
  }, [genSectionsApi.error]);

  const localHandleNext = (val) => {
    if (workSections) setSectionsResult(workSections);
    handleNext(val);
  };

  const handleGenerate = () => {
    if (workTitle.length > 5) {
      busyCallback(true);
      let req = {
        title: workTitle,
        type: formContext?.type,
        topic: formContext?.topic,
        tone: formContext?.tone,
        audience: formContext?.audience,
        description: formContext?.description,
      };
      if (selectedKeywords.length > 0) {
        let keywordsText = joinToQuotedText(selectedKeywords);
        req["keywords"] = keywordsText;
      }
      genSectionsApi.request(req);
    }
  };

  const tableSectionsChangeCallback = (sectionIndex, pointIndex, value) => {
    console.log(sectionIndex, pointIndex, value);
    let tempSections = sectionsResult;
    if (pointIndex === null) {
      tempSections[sectionIndex] = value;
    }
    workSections = [...tempSections];
  };

  const getWorkSections = () => {
    if (workSections) return workSections;
    else return formContext.sections;
  };

  const _addAtIndex = (sectionIndex) => {
    let tempSections = getWorkSections();
    let updatedSections = [
      ...tempSections.slice(0, sectionIndex+1),
      "",
      ...tempSections.slice(sectionIndex+1),
    ];
    workSections = null;
    setSectionsResult(updatedSections);
  };
  const _deleteAtIndex = (sectionIndex) => {
    let tempSections = getWorkSections();
    let updatedSections = [
      ...tempSections.slice(0, sectionIndex),
      ...tempSections.slice(sectionIndex+1),
    ];
    workSections = null;
    setSectionsResult(updatedSections);
  };
  const addRemoveCallback = (action, sectionIndex) => {
    switch (action) {
      case "ADD":
        _addAtIndex(sectionIndex);
        break;
      case "DELETE":
        _deleteAtIndex(sectionIndex);
        break;
      default:
        break;
    }
  };


  const generateTableEditor = () => {
    return (
      <SectionEditor
        sections={sectionsResult}
        sectionPoints={null}
        changeCallback={tableSectionsChangeCallback}
        addRemoveCallback={addRemoveCallback}
      />
    );
  };

  const generateConfigFirstRow = () => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={8}>
          <BorderlessItem>
            <ItemOuterDiv>
              <TextField
                style={{ width: "600px" }}
                id="ideas-standard-basic"
                label="Title"
                value={workTitle}
                variant="standard"
                onChange={(event) => setWorkTitle(event.target.value)}
              />
            </ItemOuterDiv>
          </BorderlessItem>
        </Grid>

        <Grid item xs={4}>
          <BorderlessItem style={{ marginTop: "16px" }}>
            <AutocompleteControlled
              label="Keywords"
              selection={selectedKeywords}
              keywords={formContext?.siteKeywords}
              callback={selectKeywords}
              multiple={true}
            />
          </BorderlessItem>
        </Grid>
      </Grid>
    );
  };

  const generateConfigButtons = () => {
    return (
      <>
        <BorderlessItem>
          <ButtonInnerDiv
            style={{ width: "200px", height: "28px", marginBottom: "6px" }}
            variant="contained"
            color="primary"
            onClick={handleGenerate}
          >
            Generate
          </ButtonInnerDiv>
        </BorderlessItem>
      </>
    );
  };
  const generateConfig = () => {
    return (
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <BorderlessItem>{generateConfigFirstRow()}</BorderlessItem>
        </Grid>
        <Grid item xs={12}>
          <BorderlessItem>{generateConfigButtons()}</BorderlessItem>
        </Grid>
      </Grid>
    );
  };

  const generateSectionsBox = () => {
    return (
      <Box
        component="form"
        sx={{ "& > :not(style)": { m: 1, width: "160ch" } }}
        noValidate
        autoComplete="off"
      >
        <Stack spacing={2}>
          <h2 style={{ marginBottom: "0px" }}>
            Generate sections based on the selected title
          </h2>
          <Stack spacing={2} direction="row">
            {generateConfig()}
          </Stack>
          {sectionsResult ? (
            <Grid item xs={12}>
              <Item>{generateTableEditor()}</Item>
            </Grid>
          ) : null}
        </Stack>
      </Box>
    );
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={3}>
          <Item>{generateSectionsBox()}</Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <button onClick={() => handleBackWithCleanup(false)}>Prev</button>
            <button
              disabled={nextButtonDisabled}
              onClick={() => localHandleNext(true)}
            >
              Next
            </button>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}
