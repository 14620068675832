//import Embed from '@editorjs/embed'
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import Embed from "@editorjs/embed";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";


import configData from "../../../config.json";
import TextEditorCasual from "../EditorMenuItems/TextEditorCasual";
import TextEditorExpand from "../EditorMenuItems/TextEditorExpand";
import TextEditorFormal from "../EditorMenuItems/TextEditorFormal";
import TextEditorSummary from "../EditorMenuItems/TextEditorSummary";
import TextEditorRephrase from "../EditorMenuItems/TextEditorRephrase";

export default function getEditorJDTools(model, context, errorCallback, busyCallback) {
  if (model === "FULL") {
    return {
      config: {
        checklist: CheckList,
        code: Code,
        delimiter: Delimiter,
        embed: Embed,
        header: {
          class: Header,
          shortcut: "CMD+SHIFT+H",
        },
        image: {
          class: Image,
          config: {
            endpoints: {
              byFile: configData.ChatToolBackend,
            },
          },
          //shortcut: 'CMD+SHIFT+H'
        },
        inlineCode: InlineCode,
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: configData.ChatToolBackend,
          },
        },
        list: {
          class: List,
          shortcut: "CMD+SHIFT+L",
        },

        marker: Marker,
        quote: Quote,
        raw: Raw,
        simpleImage: SimpleImage,
        table: Table,

        textRephraseTool: {
          class: TextEditorRephrase,
          config: {
            context: context,
            errorCallback: errorCallback,
            busyCallback: busyCallback,
          },
        },
        textExpandTool: {
          class: TextEditorExpand,
          config: {
            context: context,
            errorCallback: errorCallback,
            busyCallback: busyCallback,
          },
        },
        textSummaryTool: {
          class: TextEditorSummary,
          config: {
            context: context,
            errorCallback: errorCallback,
            busyCallback: busyCallback,
          },
        },
        textFormalTool: {
          class: TextEditorFormal,
          config: {
            context: context,
            errorCallback: errorCallback,
            busyCallback: busyCallback,
          },
        },
        textCasualTool: {
          class: TextEditorCasual,
          config: {
            context: context,
            errorCallback: errorCallback,
            busyCallback: busyCallback,
          },
        },
        warning: Warning,
      },
      tunes: [
        "textRephraseTool",
        "textExpandTool",
        "textSummaryTool",
        "textFormalTool",
        "textCasualTool",
      ],
      defaultBlock: "paragraph",
    };
  } else if (model === "HEADING") {
    return {
      config: {
        header: {
          class: Header,
          shortcut: "CMD+SHIFT+H",
        },
        image: {
          class: Image,
          config: {
            endpoints: {
              byFile: configData.ChatToolBackend,
            },
          },
          //shortcut: 'CMD+SHIFT+H'
        },
      },
      tunes: [],
      defaultBlock: "header",
    };
  }
}
